import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import SideDev from "./SideDev";
import "../../Accueil/Css/Navbar.css";
import { Link } from "react-router-dom";

const NavDeveloppeur = () => {
  const [sideDevActive, setSideDevActive] = useState(false);
  const sideDevRef = useRef(null); // Utilisez sideDevRef au lieu de sidedevRef

  const handleSideDevToggle = () => {
    setSideDevActive(!sideDevActive);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideDevRef.current && !sideDevRef.current.contains(event.target)) {
        setSideDevActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Navbar
        className="custom-navbar"
        variant="dark"
        expand="lg"
        style={{ minHeight: "11vh" }}
      >
        <Navbar.Brand>
          <div className="logo_blanche" style={{ marginLeft: "20%" }}>
            <img className="lolo" src="./image/logo-b.png" alt="log" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="custom-nav" style={{ marginLeft: "15%" }}>
            <Nav.Link>
              <Link
                to="/prodeveloppeur"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-card-checklist"></span>Mes Projets
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/tachedeveloppeur"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-list-task"></span>Mes Taches
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/equipedeveloppeur"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-people"></span>Mon Equipe
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-chat-dots"></span>Discussions générales
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/ "
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-bell"></span>
              </Link>
            </Nav.Link>
          </Nav>
          <Nav.Link
            style={{ marginLeft: "15%", color: "white" }}
            href="#"
            onClick={handleSideDevToggle}
          >
            {" "}
            {sideDevActive ? (
              <span className="bi bi-chevron-double-right"></span>
            ) : (
              <span className="bi bi-chevron-double-left"></span>
            )}
          </Nav.Link>
        </Navbar.Collapse>
        <SideDev ref={sideDevRef} active={sideDevActive} />
      </Navbar>
    </div>
  );
};

export default NavDeveloppeur;
