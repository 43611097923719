import React, { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import InputEmoji from "react-input-emoji";
import "./chatbox.css";
import NavbarMain from "../Accueil/Js/NavbarMain";
import url from "../url";
import Copyright from "../Accueil/Js/Copyright";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const ChatBox = () => {
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const [messageHovered, setMessageHovered] = useState(null);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = () => {
    url
      .get("/chat/")
      .then((res) => setChats(res.data))
      .catch((err) => console.log(err));
  };

  // Obtenez une liste d'utilisateurs uniques
  const uniqueUsers = Array.from(
    new Set(chats.map((chat) => chat.chat_utilisateur))
  );

  const supChat = (id) => {
    url
      .delete("/chat/" + id)
      .then(() => {
        const updatedChats = chats.filter((chat) => chat.id !== id);
        setChats(updatedChats);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const handleMouseEnter = (id) => {
    setMessageHovered(id);
  };

  const handleMouseLeave = () => {
    setMessageHovered(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      chat_contenu: message,
    };

    url
      .post("/chat", formData)
      .then((res) => {
        fetchChats(); // Mettre à jour la liste des chats
        setMessage(""); // Réinitialiser le champ de message
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <NavbarMain />
      <Stack gap={4} className="chat-box">
        <div className="chat-header">
          <h4>Discussions générales 🤩</h4>
        </div>
        <Stack gap={3} className="messages">
          {uniqueUsers.map((user) => (
            <React.Fragment key={user}>
              <div className="message-header">
                <span>{user}</span>
              </div>
              {chats
                .filter((chat) => chat.chat_utilisateur === user)
                .map((chat, index) => (
                  <div
                    className={`message self ${
                      messageHovered === chat.id ? "hovered" : ""
                    }`}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(chat.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="message-content">
                      <span>{chat.chat_contenu}</span>
                      {messageHovered === chat.id && (
                        <span
                          className="bi bi-trash-fill"
                          style={{
                            color: "grey",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => supChat(chat.id)}
                        ></span>
                      )}
                    </div>
                    <div className="message-footer">
                      {format(
                        new Date(chat.createdAt),
                        "EEE   dd/MM/yyyy HH:mm ",
                        { locale: fr }
                      )}
                    </div>
                  </div>
                ))}
            </React.Fragment>
          ))}
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack
            direction="horizontal"
            gap={3}
            className="chat-input flex-grow-0"
          >
            <InputEmoji
              value={message}
              onChange={(text) => setMessage(text)}
              fontFamily="Segoe UI Emoji, Apple Color Emoji, sans-serif"
              borderColor="rgba(72, 112, 223, 0.2)"
            />

            <button type="submit" className="btn btn-primary">
              <span className="bi bi-send" />
            </button>
          </Stack>
        </form>
      </Stack>
      <Copyright />
    </div>
  );
};

export default ChatBox;
