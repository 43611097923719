import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
const Footer = () => {
  return (
    <div
      className="footer_conteneur"
      style={{ backgroundColor: "#014F77", color: "white" }}
    >
      <div className="sous_ecran">
        <div className="container">
          <div
            className="container_footer1 row justify-center"
            style={{ marginTop: "5%" }}
          >
            <div className="logo  col-md-6 col-lg-3 div mb-4">
              <img
                className="logo_foot"
                id="logo_foot"
                alt="logo_foot"
                src="image/logoV1.png"
                style={{
                  width: "35%",
                  marginTop: "5%",
                  marginLeft: "22%",
                  marginBottom: "5%",
                }}
              />
              <p>
                Mestre est une application qui sert a simplifier la gestion de
                projets de développement logiciel en fournissant des outils pour
                mieux planifier, coordonner et suivre nos efforts.
              </p>
            </div>
            <div className="service col-md-6 col-lg-3 div mb-4 ">
              <div style={{ marginTop: "9%" }}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>Services</p>
                Gestion de developpeurs
                <br />
                Gestion des taches
                <br />
                gestion des equipes
                <br />
              </div>
            </div>
            <div className="ressource col-md-6 col-lg-3 div mb-4 justify-center">
              <div style={{ marginTop: "9%" }}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>Divers</p>
                <span className="justify-center" href="#">
                  Ressources Utiles
                </span>
                <span href="#">Actualités Locales</span>
                <span href="#">Événements Inspirants</span>
                <span href="#">Partage de Connaissances </span>
                <span href="#">Accès Pratique</span>
              </div>
            </div>
            <div className="contact col-md-6 col-lg-3 div mb-4">
              <div style={{ marginTop: "9%" }}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Coordonnées
                </p>
                <span className="mail">
                  <span
                    className="bi bi-envelope-at"
                    style={{ marginRight: "3%" }}
                  />
                  info@mestre.com
                </span>
                <br />
                <span className="phone">
                  <span
                    className="bi bi-telephone"
                    style={{ marginRight: "3%" }}
                  />
                  +261 33 33 333 33
                </span>
                <br />
                <span className="lieu">
                  <span className="text_lieu">
                    <span
                      className="bi bi-geo-alt"
                      style={{ marginRight: "3%" }}
                    />
                    Lot VK 17A Fenomanana-Mahazoarivo Antananarivo-101 <br />
                    Madagascar
                  </span>
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="container_footer2 row" align="center">
            <div className="copyright col-lg-12 col-md-6 ">
              <p>
                Tous droits résérvés a Mestre
                <span className="bi bi-c-circle" /> Haisoa 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
