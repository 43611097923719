import React, { useState, useEffect } from "react";
import "../Css/client.css";
import NavbarMain from "../../Accueil/Js/NavbarMain";
import Copyright from "../../Accueil/Js/Copyright";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import url from "../../url";

const Client = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    client_nom: "",
    client_email: "",
    client_contact: "",
  });
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleShowModal = () => setShowModal(true);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!formData.client_nom.trim()) {
      newErrors.client_nom = "Le nom du client est requis.";
    }

    if (!formData.client_email.trim()) {
      newErrors.client_email = "L'email du client est requis.";
    } else if (!isValidEmail(formData.client_email)) {
      newErrors.client_email = "L'email du client n'est pas valide.";
    }

    if (!formData.client_contact.trim()) {
      newErrors.client_contact = "Le contact du client est requis.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Aucune erreur, continuer avec la soumission du formulaire
      url
        .post("/client", formData)
        .then((res) => {
          setClients([...clients, res.data]);
          resetForm();
          handleCloseModal();
          afficheClient();
        })
        .catch((err) => console.log(err));
    }
  };

  const resetForm = () => {
    setFormData({
      client_nom: "",
      client_email: "",
      client_contact: "",
    });
    setErrors({});
  };
  const isValidEmail = (email) => {
    // Expression régulière pour valider l'adresse e-mail
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  };

  const columns = [
    {
      name: "Nom ",
      selector: "client_nom",
      sortable: true,
    },
    {
      name: "Email",
      selector: "client_email",
      sortable: true,
    },
    {
      name: "Contact",
      selector: "client_contact",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => handleEditClient(row)}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-pencil-square"></span>
          </button>
          <button className="btn btn-danger" onClick={() => supClient(row.id)}>
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const afficheClient = async () => {
    url
      .get("/client/")
      .then((res) => setClients(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    afficheClient();
  }, []);

  const supClient = (id) => {
    url
      .delete("/client/" + id)
      .then(() => {
        const updatedClients = clients.filter((client) => client.id !== id);
        setClients(updatedClients);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const handleUpdateClient = (updatedData) => {
    const updatedIndex = clients.findIndex(
      (client) => client.id === updatedData.id
    );

    if (updatedIndex !== -1) {
      const updatedClients = [...clients];
      updatedClients[updatedIndex] = updatedData;
      setClients(updatedClients);
    }
  };

  const handleEditClient = (client) => {
    console.log("Editing client:", client);
    setClientToEdit({
      id: client.id,
      client_nom: client.client_nom,
      client_email: client.client_email,
      client_contact: client.client_contact,
    });
    setShowModifyModal(true);
  };

  const modifClient = (id, editedData) => {
    url
      .put("/client/" + id, {
        id: editedData.id,
        client_nom: editedData.client_nom,
        client_email: editedData.client_email,
        client_contact: editedData.client_contact,
      })
      .then((res) => {
        const updatedClients = clients.map((client) =>
          client.id === id ? editedData : client
        );
        setClients(updatedClients);
        window.alert("Modification avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  const [clientToEdit, setClientToEdit] = useState({
    client_nom: "",
    client_email: "",
    client_contact: "",
  });

  const saveModifiedClient = (modifiedData) => {
    handleUpdateClient(modifiedData);
  };

  const ModifyClientModal = ({ show, onHide, formData }) => {
    const [editedData, setEditedData] = useState(formData);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
    return (
      <Modal show={show} onHide={onHide} style={{ top: "10%", left: "0%" }}>
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Modification des détails du client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="modal_projet">
              <input
                type="hidden"
                name="id"
                className={`form-control`}
                value={editedData.id}
                onChange={handleChange}
              />
            </div>
            <div className="modal_dev">
              <label>Nom :</label>
              <input
                type="text"
                name="client_nom"
                className={`form-control`}
                value={editedData.client_nom}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Email:</label>
              <input
                type="email"
                name="client_email"
                className={`form-control`}
                value={editedData.client_email}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Contact :</label>
              <input
                type="number"
                name="client_contact"
                className={`form-control`}
                value={editedData.client_contact}
                onChange={handleChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={() => {
              modifClient(editedData.id, editedData);
              onHide();
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };

  return (
    <div className="client_conteneur">
      <div className="client_nav">
        <NavbarMain />
      </div>
      <div>
        <h5 className="client_titre">GESTION DES CLIENTS</h5>
      </div>

      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div className="ajout_equipe">
          <button
            className="btn btn-primary"
            onClick={handleShowModal}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-plus-lg"></span>Ajouter
          </button>
        </div>
        <div className="recherche_projet">
          <div className="input-group mb-3 custom-search">
            <input
              type="text"
              placeholder="Rechercher..."
              className="form-control"
              style={{
                borderRadius: "5px 0 0 5px",
                borderTop: "2px solid #ccc",
                borderBottom: "2px solid #ccc",
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ top: "10%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Ajouter ici les détails du client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="modal_dev">
              <label>Nom :</label>
              <input
                type="text"
                name="client_nom"
                className={`form-control ${errors.client_nom ? "error" : ""}`}
                value={formData.client_nom}
                onChange={handleChange}
              />
              {errors.client_nom && (
                <span className="error_message">{errors.client_nom}</span>
              )}
            </div>
            <div className="from-group">
              <label>Email:</label>
              <input
                type="email"
                name="client_email"
                className={`form-control ${errors.client_email ? "error" : ""}`}
                value={formData.client_email}
                onChange={handleChange}
              />
              {errors.client_email && (
                <span className="error_message">{errors.client_email}</span>
              )}
            </div>
            <div className="from-group">
              <label>Contact :</label>
              <input
                type="number"
                name="client_contact"
                className={`form-control ${
                  errors.client_contact ? "error" : ""
                }`}
                value={formData.client_contact}
                onChange={handleChange}
              />
              {errors.client_contact && (
                <span className="error_message">{errors.client_contact}</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={handleSubmit}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="client_table" style={{ marginBottom: "3%" }}>
        <DataTable
          columns={columns}
          data={clients}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>
      <ModifyClientModal
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        formData={clientToEdit}
        onUpdate={handleUpdateClient}
        onSave={saveModifiedClient}
      />
      <div className="client_footer">
        <Copyright />
      </div>
    </div>
  );
};

export default Client;
