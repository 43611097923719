import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import { Navbar, Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import url from "../../url";
import "../Css/Navbar.css";

const NavbarMain = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(3);
  const sidebarRef = useRef(null);
  const [projets, setProjets] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const handleSidebarToggle = () => {
    setSidebarActive(!sidebarActive);
  };

  const handleNotificationClick = () => {
    setNotificationCount(0);
    setShowNotificationModal(true);
  };

  useEffect(() => {
    url
      .get("/projet/")
      .then((res) => setProjets(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fonction pour vérifier si la date de fin est proche de la date actuelle (3 jours ou moins)
  const isEndDateClose = (endDate) => {
    const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3 jours en millisecondes
    const endDateTime = new Date(endDate).getTime();
    const currentDate = new Date().getTime();
    return endDateTime - currentDate <= threeDaysInMillis;
  };

  useEffect(() => {
    function fetchNotifications() {
      const closeDeadlineProjects = projets.filter((project) =>
        isEndDateClose(project.projet_date_fin)
      );

      const newNotifications = closeDeadlineProjects.map((project) => {
        const daysRemaining = getDaysRemaining(project.projet_date_fin);
        const isAlreadyOverdue = daysRemaining < 0;
        return {
          message: isAlreadyOverdue
            ? `Le projet ${project.projet_nom} est déjà en retard de ${Math.abs(
                daysRemaining
              )} jours.`
            : `La date de fin du projet ${project.projet_nom} est proche.`,
          daysRemaining,
          backgroundColor: isAlreadyOverdue ? "red" : "lightgray",
          date: new Date(),
        };
      });

      setNotifications(newNotifications);
    }

    fetchNotifications(); // Initial fetch
    const refreshInterval = setInterval(fetchNotifications, 60000); // Rafraîchissement toutes les minutes

    return () => {
      clearInterval(refreshInterval);
    };
  }, [projets]);

  // Fonction pour obtenir le nombre de jours restants
  const getDaysRemaining = (endDate) => {
    // eslint-disable-next-line
    const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3 jours en millisecondes
    const endDateTime = new Date(endDate).getTime();
    const currentDate = new Date().getTime();
    const remainingTime = endDateTime - currentDate;
    const remainingDays = Math.ceil(remainingTime / (24 * 60 * 60 * 1000));
    return remainingDays;
  };

  return (
    <div>
      <Navbar
        className="custom-navbar"
        variant="dark"
        expand="lg"
        style={{ minHeight: "11vh" }}
      >
        <Navbar.Brand>
          <div
            className="logo_blanche"
            style={{
              marginLeft: "20%",
              position: "relative",
            }}
          >
            <Link to="/accueil">
              <img className="lolo" src="./image/logo-b.png" alt="log" />
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="custom-nav" style={{ marginLeft: "15%" }}>
            <Nav.Link>
              <Link
                to="/projets"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <span className="bi bi-card-checklist" />
                Projets
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/taches"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span className="bi bi-list-task" />
                Tâches
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/developpeur"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span className="bi bi-person-gear" />
                Développeurs
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/equipes"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span className="bi bi-people" />
                Équipes
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/clients"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span className="bi bi-person-heart" />
                Clients
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/chat"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span className="bi bi-chat-dots" />
                Discussions générales
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="" style={{ textDecoration: "none", color: "white" }}>
                <span
                  className="bi bi-bell"
                  onClick={handleNotificationClick}
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  {notificationCount > 0 && (
                    <span
                      className="notification-badge"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        padding: "0px 6px",
                        fontSize: "10px",
                        position: "absolute",
                        top: "0",
                        left: "10px",
                      }}
                    >
                      {notificationCount}
                    </span>
                  )}
                </span>
              </Link>
            </Nav.Link>
          </Nav>
          <Nav.Link
            style={{ marginLeft: "3%", color: "white" }}
            href="#"
            onClick={handleSidebarToggle}
          >
            {" "}
            {sidebarActive ? (
              <span className="bi bi-chevron-double-right" />
            ) : (
              <span className="bi bi-chevron-double-left" />
            )}
          </Nav.Link>
        </Navbar.Collapse>
        <Sidebar ref={sidebarRef} active={sidebarActive} />
      </Navbar>

      <NotificationModal
        show={showNotificationModal}
        onHide={() => setShowNotificationModal(false)}
        notifications={notifications}
        notificationCount={notificationCount}
      />
    </div>
  );
};

const NotificationModal = ({
  show,
  onHide,
  notifications,
  notificationCount,
}) => {
  /* Ajout de notificationCount */
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notifications.map((notification, index) => (
          <div
            key={index}
            style={{
              backgroundColor: notification.backgroundColor,
              marginBottom: "1%",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <p>
              {notification.message} {notification.daysRemaining} jours restants
            </p>
            <p>
              Date d'arrivée de la notification:{" "}
              {notification.date.toLocaleString()}
            </p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
        {notificationCount > 0 && ( // Badge avec le nombre de notifications
          <span
            className="notification-badge"
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "0px 6px",
              fontSize: "10px",
              position: "absolute",
              top: "0",
              left: "10px",
            }}
          >
            {notificationCount}
          </span>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default NavbarMain;
