import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projet from "./Projet/Js/Projet";
import Navbar from "./Accueil/Js/Navbar";
import Footer from "./Accueil/Js/Footer";
import Taches from "./Projet/Js/Taches";
import Equipes from "./Projet/Js/Equipes";
import Copyright from "./Accueil/Js/Copyright";
import Developpeur from "./Projet/Js/Developpeur";
import Client from "./Projet/Js/Client";
import Landing from "./Accueil/Js/Landing";
import Login from "./Accueil/Js/Login";
import NavDeveloppeur from "./Developpeur/Js/NavDeveloppeur";
import Prodeveloppeur from "./Developpeur/Js/Prodeveloppeur";
import Tachedeveloppeur from "./Developpeur/Js/Tachedeveloppeur";
import Equipedeveloppeur from "./Developpeur/Js/Equipedeveloppeur";
import Inscription from "./Accueil/Js/Inscription";
import MdpOublie from "./Accueil/Js/MdpOublie";
import Navlanding from "./Accueil/Js/Navlanding";
import "react-toastify/dist/ReactToastify.css";
import Accueil from "./Accueil/Js/Accueil";
import ChatBox from "./Chat/ChatBox";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/projets" element={<Projet />}></Route>
        <Route path="/navbar" element={<Navbar />}></Route>
        <Route path="/footer" element={<Footer />}></Route>
        <Route path="/taches" element={<Taches />}></Route>
        <Route path="/equipes" element={<Equipes />}></Route>
        <Route path="/copyright" element={<Copyright />}></Route>
        <Route path="/developpeur" element={<Developpeur />}></Route>
        <Route path="/clients" element={<Client />}></Route>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/navDeveloppeur" element={<NavDeveloppeur />}></Route>
        <Route path="/proDeveloppeur" element={<Prodeveloppeur />}></Route>
        <Route path="/tachedeveloppeur" element={<Tachedeveloppeur />}></Route>
        <Route path="/accueil" element={<Accueil />}></Route>
        <Route
          path="/equipedeveloppeur"
          element={<Equipedeveloppeur />}
        ></Route>
        <Route path="/inscri" element={<Inscription />}></Route>
        <Route path="/mdpoubli" element={<MdpOublie />}></Route>
        <Route path="/navland" element={<Navlanding />}></Route>
        <Route path="/chat" element={<ChatBox />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
