import React, { useState, useEffect } from "react";
import NavbarMain from "../../Accueil/Js/NavbarMain";
import "../Css/Projet.css";
import Copyright from "../../Accueil/Js/Copyright";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import url from "../../url";
const { PDFDocument, rgb, StandardFonts } = require("pdf-lib");

const ProjectDetailsModal = ({ show, onHide, project }) => {
  if (!project) {
    return null;
  }
  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { height } = page.getSize();
    const text = `Nom du projet: ${project.projet_nom}\nBudget du projet: ${project.projet_budget}\nCharge du projet : ${project.projet_charge}\nDate de debut du projet : ${project.projet_date_debut}\nDate de fin  : ${project.projet_date_fin}\nDurée du projet : ${project.projet_duree} Jours\nChef d projet : ${project.projet_chef}\nStatut du projet : ${project.projet_statut}`;

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;

    const x = 100;
    let y = height - 100;

    text.split("\n").forEach((line) => {
      page.drawText(line, {
        x,
        y,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      y -= fontSize + 5;
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl);
  };
  return (
    <Modal show={show} onHide={onHide} style={{ top: "7%", left: "0%" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span
            className="bi bi-bookmark-star-fill"
            style={{ color: "#014F77" }}
          />
          Détails du Projet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Nom du projet:</b> &nbsp;&nbsp;&nbsp;&nbsp; {project.projet_nom}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Budget: </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_budget}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Charge du projet : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_charge}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Date de debut du projet : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_date_debut}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Date de la fin du projet : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_date_fin}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Pieces jointes : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_jointes}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Durée du projet : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_duree} Jours
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Chef de projet : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_chef}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Equipes assignées au projet :</b>&nbsp;&nbsp; &nbsp; &nbsp;{" "}
            {project.projet_equipe}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Developpeurs assignés au projet :</b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_developpeur}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Statut du projet :</b>&nbsp;&nbsp;&nbsp;&nbsp;
            {project.projet_statut}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fermer
        </Button>
        <Button variant="success" onClick={generatePDF}>
          Générer un Pdf
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Projet = () => {
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState("");
  const [duration, setDuration] = useState("");
  const [projets, setProjets] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [developpeur, setDeveloppeurs] = useState([]);
  const [equipe, setEquipes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false);
  };

  const handleUpdateProject = (updatedData) => {
    const updatedIndex = projets.findIndex(
      (project) => project.id === updatedData.id
    );

    if (updatedIndex !== -1) {
      const updatedProjects = [...projets];
      updatedProjects[updatedIndex] = updatedData;
      setProjets(updatedProjects);
    }
  };

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleShowDetailsModal = (project) => {
    setSelectedProject(project);
    setShowDetailsModal(true);
  };

  const [projet_nom, setProjetNom] = useState("");
  const [projet_budget, setProjetBudget] = useState("");
  const [projet_charge, setProjetCharge] = useState("");
  const [projet_jointes, setProjetJointes] = useState("");
  const [projet_date_debut, setProjetDateDebut] = useState("");
  const [projet_date_fin, setProjetDateFin] = useState("");
  const [projet_chef, setProjetChef] = useState("");
  const [projet_equipe, setProjetEquipe] = useState("");
  const [projet_developpeur, setProjetDeveloppeur] = useState("");

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!projet_nom) {
      newErrors.projet_nom = "Le nom du projet est requis.";
    }

    if (!projet_budget) {
      newErrors.projet_budget = "Le budget est requis.";
    }

    if (!projet_charge) {
      newErrors.projet_charge = "La charge est requise.";
    }

    if (!projet_date_debut) {
      newErrors.projet_date_debut = "La date de début est requise.";
    }

    if (!projet_date_fin) {
      newErrors.projet_date_fin = "La date de fin est requise.";
    }

    const debutDate = new Date(projet_date_debut);
    const finDate = new Date(projet_date_fin);

    if (debutDate > finDate) {
      setDateError("La date de début doit être antérieure à la date de fin.");
    } else {
      setDateError("");
    }
    // Vérification si des erreurs existent
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Aucune erreur, continuer avec la soumission du formulaire
      url
        .post("/projet", {
          projet_nom: projet_nom,
          projet_budget: projet_budget,
          projet_charge: projet_charge,
          projet_jointes: projet_jointes,
          projet_date_debut: projet_date_debut,
          projet_date_fin: projet_date_fin,
          projet_chef: projet_chef,
          projet_developpeur: projet_developpeur,
          projet_equipe: projet_equipe,
        })
        .then((res) => {
          setProjets([...projets, res.data]);
          resetForm();
          handleCloseModal();
          afficheProjet();
        })
        .catch((err) => console.log(err));
    }
  };
  const saveModifiedProject = (modifiedData) => {
    handleUpdateProject(modifiedData);
  };

  const handleEditProject = (project) => {
    console.log("Editing project:", project);
    setProjectToEdit({
      id: project.id,
      projet_nom: project.projet_nom,
      projet_budget: project.projet_budget,
      projet_charge: project.projet_charge,
      projet_date_debut: project.projet_date_debut,
      projet_date_fin: project.projet_date_fin,
    });
    setShowModifyModal(true);
  };

  const handleAssignProject = (project) => {
    console.log("Editing project:", project);
    setProjectToEdit({
      id: project.id,
      projet_chef: project.projet_chef,
      projet_equipe: project.projet_equipe,
      projet_developpeur: project.projet_developpeur,
    });
    setShowAssignModal(true);
  };

  const modifProjet = (id, editedData) => {
    url
      .put("/projet/" + id, {
        id: editedData.id,
        projet_nom: editedData.projet_nom,
        projet_budget: editedData.projet_budget,
        projet_charge: editedData.projet_charge,
        projet_date_debut: editedData.projet_date_debut,
        projet_date_fin: editedData.projet_date_fin,
        projet_chef: editedData.projet_chef,
        projet_equipe: editedData.projet_equipe,
        projet_developpeur: editedData.projet_developpeur,
      })
      .then((res) => {
        const updatedProjects = projets.map((project) =>
          project.id === id ? editedData : project
        );
        setProjets(updatedProjects);
        window.alert("Modification avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  const assignProjet = (id, editedData) => {
    url
      .put("/projet/" + id, {
        id: editedData.id,
        projet_chef: editedData.projet_chef,
        projet_equipe: editedData.projet_equipe,
        projet_developpeur: editedData.projet_developpeur,
      })
      .then((res) => {
        const updatedProjects = projets.map((project) =>
          project.id === id ? editedData : project
        );
        setProjets(updatedProjects);
        window.alert("Modification avec succès");
        afficheProjet();
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  projets.forEach((projet) => {
    if (typeof projet === "object") {
      // Convertir les dates de début et de fin en objets Date
      const dateDebut = new Date(projet.projet_date_debut);
      const dateFin = new Date(projet.projet_date_fin);

      // Calculer la durée en jours
      const differenceEnTemps = dateFin - dateDebut;
      const differenceEnJours = differenceEnTemps / (1000 * 60 * 60 * 24);

      // Mettre la durée calculée dans projet_duree
      projet.projet_duree = differenceEnJours;
    }
  });

  const handleShowModal = () => setShowModal(true);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const resetForm = () => {
    setProjetNom("");
    setProjetBudget("");
    setProjetCharge("");
    setProjetJointes("");
    setProjetDateDebut("");
    setProjetDateFin("");
    setDateError("");
    setProjetChef("");
    setProjetEquipe("");
    setProjetDeveloppeur("");
    setDuration("");
  };

  useEffect(() => {
    url
      .get("/developpeur/")
      .then((res) => {
        setDeveloppeurs(res.data);
        return url.get("/equipe/");
      })
      .then((res) => {
        setEquipes(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const columns = [
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            color: "#014F77",
            marginRight: "7px",
          }}
          onClick={() => handleShowDetailsModal(row)}
        >
          <span className="bi bi-clipboard-plus-fill"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Nom",
      selector: "projet_nom",
      sortable: true,
    },
    {
      name: "Budget",
      cell: (row) => `${row.projet_budget} Ar`,
      sortable: true,
    },
    {
      name: "Charge",
      cell: (row) => `${row.projet_charge} Ar`,
      sortable: true,
    },

    {
      name: "Date de début",
      selector: "projet_date_debut",
      sortable: true,
    },
    {
      name: "Date de fin",
      selector: "projet_date_fin",
      sortable: true,
    },
    {
      name: "Durée",
      cell: (row) => `${row.projet_duree} Jours`,
      sortable: true,
    },
    {
      name: "Statut",
      selector: "projet_statut",
      sortable: true,
    },
    {
      name: "Assignation",
      cell: (row) => (
        <button
          className="btn btn-success"
          onClick={() => handleAssignProject(row)}
          style={{ textDecoration: "none", marginRight: "7px" }}
        >
          <span className="bi bi-person-check"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => handleEditProject(row)}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-pencil-square"></span>
          </button>

          <button className="btn btn-danger" onClick={() => supProjet(row.id)}>
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };
  const afficheProjet = async () => {
    url
      .get("/projet/")
      .then((res) => setProjets(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    afficheProjet();
  }, []);

  const rechercheDeuxdate = async () => {
    try {
      const response = await url.get("/projet/searchBetweenDates", {
        params: {
          startDate,
          endDate,
        },
      });
      setProjets(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const supProjet = (id) => {
    url
      .delete("/projet/" + id)
      .then(() => {
        const updatedProjects = projets.filter((project) => project.id !== id);
        setProjets(updatedProjects);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const AssignProjectModal = ({ show, onHide, formData, onSave }) => {
    const [editedData, setEditedData] = useState({
      id: formData.id,
      projet_equipe: [],
    });

    const handleChange = (e) => {
      const { name, value, checked } = e.target;

      if (name === "projet_equipe") {
        if (checked) {
          setEditedData({
            ...editedData,
            projet_equipe: [...editedData.projet_equipe, value],
          });
        } else {
          setEditedData({
            ...editedData,
            projet_equipe: editedData.projet_equipe.filter(),
          });
        }
      } else {
        setEditedData({
          ...editedData,
          [name]: value,
        });
      }
    };

    const handleAssignClick = () => {
      const projetEquipeString = Array.isArray(editedData.projet_equipe)
        ? editedData.projet_equipe.join(",")
        : "";

      // Mettre à jour les données à envoyer au serveur
      const updatedData = {
        ...editedData,
        projet_equipe: projetEquipeString,
      };

      // Appeler onSave avec les données mises à jour
      onSave(updatedData);
      onHide();
    };

    return (
      <Modal
        show={showAssignModal}
        onHide={handleCloseAssignModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Assignation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <form>
            <div>
              <div className="form-group">
                <input
                  type="text"
                  name="id"
                  className={`form-control`}
                  value={editedData.id}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>Chef de projet:</label>
                <select
                  value={editedData.projet_chef}
                  onChange={handleChange}
                  name="projet_chef"
                  className={`form-control ${
                    errors.projet_chef ? "error" : ""
                  }`}
                >
                  <option value="">
                    Veuillez sélectionner un chef de projet
                  </option>
                  {developpeur.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.developpeur_nom} {item.developpeur_prenoms}
                    </option>
                  ))}
                </select>

                {errors.projet_chef && (
                  <span className="error_message">
                    {errors.projets_equipes}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>
                  Choisir des équipes:
                </label>
                {equipe.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="projet_equipe"
                      value={editedData.projet_equipe}
                      onChange={handleChange}
                      style={{ marginRight: "8px" }}
                    />
                    <span>{item.equipe_nom}</span>
                  </div>
                ))}
              </div>

              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>
                  Choisir des développeurs:
                </label>
                {developpeur.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="projet_developpeur"
                      value={editedData.projet_developpeur}
                      onChange={handleChange}
                      style={{ marginRight: "8px" }}
                    />
                    <span>{item.developpeur_nom}</span>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseAssignModal}>
            Fermer
          </Button>
          <Button
            variant="primary"
            onClick={handleAssignClick}
            style={{ backgroundColor: "#014F77", color: "white" }}
          >
            Assigner
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModifyProjectModal = ({ show, onHide, formData }) => {
    const [editedData, setEditedData] = useState(formData);

    const handleChange = (e) => {
      const { name, value, type } = e.target;
      if (type === "checkbox") {
        const isChecked = e.target.checked;
        setEditedData((prevData) => ({
          ...prevData,
          [name]: isChecked
            ? [...prevData[name], value] // Ajoutez la valeur à la liste si la case est cochée
            : prevData[name].filter((item) => item !== value), // Supprimez la valeur de la liste si la case est décochée
        }));
      } else {
        setEditedData({
          ...editedData,
          [name]: value,
        });
      }
    };
    return (
      <Modal show={show} onHide={onHide} style={{ top: "10%", left: "0%" }}>
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Modifier les détails du projet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="modal_projet">
              <input
                type="hidden"
                name="id"
                className={`form-control`}
                value={editedData.id}
                onChange={handleChange}
              />
            </div>
            <div className="modal_projet">
              <label>Nom du projet:</label>
              <input
                type="text"
                name="projet_nom"
                className={`form-control`}
                value={editedData.projet_nom}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Budget du projet:</label>
              <input
                type="number"
                name="projet_budget"
                className={`form-control`}
                value={editedData.projet_budget}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Charge du projet:</label>
              <input
                type="number"
                name="projet_charge"
                className={`form-control`}
                value={editedData.projet_charge}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Date de début:</label>
              <input
                type="date"
                name="projet_date_debut"
                className={`form-control`}
                value={editedData.projet_date_debut}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Date de fin:</label>
              <input
                type="date"
                name="projet_date_fin"
                className={`form-control`}
                value={editedData.projet_date_fin}
                onChange={handleChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={() => {
              modifProjet(editedData.id, editedData);
              onHide();
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const [projectToEdit, setProjectToEdit] = useState({
    projet_nom: "",
    projet_budget: "",
    projet_charge: "",
    projet_date_debut: "",
    projet_date_fin: "",
    projet_equipe: "",
    projet_chef: "",
    projet_developpeur: "",
  });

  return (
    <div className="projet_conteneur">
      <div className="nav_projet">
        <NavbarMain />
      </div>
      <div>
        <h4 className="projet_titre">Gestion des projets</h4>
      </div>
      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div className="ajout_projet">
          <button
            className="btn btn-primary"
            onClick={handleShowModal}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-plus-lg"></span>Ajouter
          </button>
        </div>
        <div className="recherche_projet">
          <div className="input-group mb-3 custom-search">
            <input
              type="text"
              placeholder="Rechercher..."
              className="form-control"
              style={{
                borderRadius: "5px 0 0 5px",
                borderTop: "2px solid #ccc",
                borderBottom: "2px solid #ccc",
                marginRight: "7px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div
          className="input-group mb-3 custom-search"
          style={{ marginRight: "7px" }}
        >
          <input
            type="date"
            placeholder="Date de début"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ height: "38px", width: "100px" }}
          />
        </div>
        <div className="input-group mb-3 custom-search">
          <input
            type="date"
            placeholder="Date de fin"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ height: "38px", width: "100px", marginRight: "2%" }}
          />
        </div>
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "#014F77",
            height: "38px",
          }}
          onClick={rechercheDeuxdate}
        >
          Rechercher
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Ajout de projet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit1}>
            <div className="modal_projet">
              <label>Nom du projet:</label>
              <input
                type="text"
                name="projet_nom"
                className={`form-control ${errors.projet_nom ? "error" : ""}`}
                value={projet_nom}
                onChange={(e) => setProjetNom(e.target.value)}
              />
              {errors.projet_nom && (
                <span className="error_message">{errors.projet_nom}</span>
              )}
            </div>
            <div className="from-group">
              <label>Budget du projet:</label>
              <input
                type="number"
                name="projet_budget"
                className={`form-control ${
                  errors.projet_budget ? "error" : ""
                }`}
                value={projet_budget}
                onChange={(e) => setProjetBudget(e.target.value)}
              />
              {errors.projet_budget && (
                <span className="error_message">{errors.projet_budget}</span>
              )}
            </div>
            <div className="from-group">
              <label>Charge:</label>
              <input
                type="number"
                name="projet_charge"
                className={`form-control ${
                  errors.projet_charge ? "error" : ""
                }`}
                value={projet_charge}
                onChange={(e) => setProjetCharge(e.target.value)}
              />
              {errors.projet_charge && (
                <span className="error_message">{errors.projet_charge}</span>
              )}
            </div>
            <div className="from-group">
              <label>Date de début:</label>
              <input
                type="date"
                name="projet_date_debut"
                className={`form-control ${
                  errors.projet_date_debut || dateError ? "error" : ""
                }`}
                value={projet_date_debut}
                onChange={(e) => setProjetDateDebut(e.target.value)}
              />
              {errors.projet_date_debut && (
                <span className="error_message">
                  {errors.projet_date_debut}
                </span>
              )}
              {dateError && <span className="error_message">{dateError}</span>}
            </div>
            <div className="from-group">
              <label>Date de fin:</label>
              <input
                type="date"
                name="projet_date_fin"
                className={`form-control ${
                  errors.projet_date_fin || dateError ? "error" : ""
                }`}
                value={projet_date_fin}
                onChange={(e) => setProjetDateFin(e.target.value)}
              />
              {errors.projet_date_fin && (
                <span className="error_message">{errors.projet_date_fin}</span>
              )}
              {dateError && <span className="error_message">{dateError}</span>}
            </div>
            <div className="form-group">
              <label>Pièces jointes:</label>
              <input
                type="file"
                name="projet_jointes"
                className="form-control"
                onChange={(e) => setProjetJointes(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={handleSubmit1}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="projet_table" style={{ marginBottom: "3%" }}>
        <DataTable
          columns={columns}
          data={projets}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>

      <ModifyProjectModal
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        formData={projectToEdit}
        onUpdate={handleUpdateProject}
        onSave={saveModifiedProject}
      />

      <AssignProjectModal
        show={showAssignModal}
        onHide={() => setShowAssignModal(false)}
        formData={projectToEdit}
        onUpdate={handleUpdateProject}
        onSave={(updatedData) => {
          assignProjet(updatedData.id, updatedData);
        }}
      />

      <ProjectDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        project={selectedProject}
      />

      <div className="projet_footer">
        <Copyright />
      </div>
    </div>
  );
};

export default Projet;
