import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../Css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import url from "../../url";

const Login = () => {
  const [values, setValues] = useState({
    utilisateur_email: "",
    mot_de_passe: "",
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(values);

    if (!validationErrors.utilisateur_email && !validationErrors.mot_de_passe) {
      url
        .post("/inscription/log", {
          utilisateur_email: values.utilisateur_email,
          mot_de_passe: values.mot_de_passe,
        })
        .then((res) => {
          if (!res.data.error) {
            if (res.data.utilisateur_type === "Developpeur") {
              navigate("/proDeveloppeur");
              sessionStorage.setItem("accessToken", res.data.accessToken);
            } else if (res.data.utilisateur_type === "Client") {
              navigate("/");
              sessionStorage.setItem("accessToken", res.data.accessToken);
            } else if (res.data.utilisateur_type === "Client") {
              navigate("/");
              sessionStorage.setItem("accessToken", res.data.accessToken);
            } else if (res.data.utilisateur_type === "Administrateur") {
              navigate("/accueil");
              sessionStorage.setItem("accessToken", res.data.accessToken);
            }
          } else {
            alert("Identifiants incorrects");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.utilisateur_email) {
      errors.utilisateur_email = "Champ requis";
    }

    if (!data.mot_de_passe) {
      errors.mot_de_passe = "Champ requis";
    }

    return errors;
  };

  return (
    <div className="login_conteneur" style={{ marginTop: "5vh" }}>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-7">
            <div
              className="image_auth"
              style={{ marginTop: "5vh", marginRight: "5vw" }}
            >
              <img
                className="mimi img-fluid"
                src="./image/login.png"
                alt="authentification"
              ></img>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="card-body"
              style={{
                marginTop: "5vh",
                marginRight: "2vw",
                marginLeft: "2vw",
              }}
            >
              <img
                className="aut_logo img-fluid"
                src="./image/logoV1.png"
                alt="lolo"
                style={{
                  width: "30%",
                  height: "30%",
                  marginLeft: "20%",
                  marginBottom: "10%",
                }}
              ></img>
              <form className="w-70" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="utilisateur_email" className="form-label">
                    Votre email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      errors.utilisateur_email ? "is-invalid" : ""
                    }`}
                    id="utilisateur_email"
                    name="utilisateur_email"
                    value={values.utilisateur_email}
                    required
                    onChange={handleInput}
                  />
                  {errors.utilisateur_email && (
                    <div className="invalid-feedback">
                      {errors.utilisateur_email}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="mot_de_passe" className="form-label">
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    className={`form-control ${
                      errors.mot_de_passe ? "is-invalid" : ""
                    }`}
                    id="mot_de_passe"
                    name="mot_de_passe"
                    value={values.mot_de_passe}
                    required
                    onChange={handleInput}
                  />
                  {errors.mot_de_passe && (
                    <div className="invalid-feedback">
                      {errors.mot_de_passe}
                    </div>
                  )}
                </div>
                <div className="d-grid gap-4 mb-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 bg-014F77"
                    style={{
                      backgroundColor: "#014F77",
                      color: "white",
                      outline: "none",
                    }}
                  >
                    Se connecter
                  </button>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <button type="button" className="btn btn-danger w-100">
                      Annuler
                    </button>
                  </Link>
                </div>
              </form>
              <p className="mt-3">
                Vous n'avez pas encore un compte?{" "}
                <Link to="/inscri" style={{ textDecoration: "none" }}>
                  {" "}
                  S'inscrire
                </Link>
              </p>
              <p>
                <Link to="/mdpoubli" style={{ textDecoration: "none" }}>
                  Mot de passe oublié?
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
