import React from 'react';
import '../Css/copyright.css';

const Copyright = () => {
  return (
    <div className='copy_conteneur'>
      <div className='cop_copyright'>
        <p className='co'>Tous droits réservés MESTRE <span class="bi bi-c-circle"></span> 2023 Haisoa</p>
      </div>
    </div>
  )
}

export default Copyright;
