import React, { useState } from 'react';
import '../Css/Landing.css';
import Footer from './Footer';
import Navlanding from './Navlanding';

const Landing = () => {

  const [formData, setFormData] = useState({
    nom: '',
    email: '',
    message: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.nom || !formData.email || !formData.message) {
      setErrorMessage('Tous les champs sont requis.');
    } else {
      setErrorMessage('');
      console.log('Données du formulaire :', formData);
    }
  };
  return (
    <div className='landing_conteneur'>
      <div className='nav_landing'>
        <Navlanding/>
      </div>
      <div className='landing_image'>
        <img className='ima' src="./image/Landing.png" alt='landing'/>
      </div>
      <div className='manaraka'>
        <p className='tt'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
      </div>
      <div className='coco'>
        <ul className='cocontenu'>
            <li>
                <span class="bi bi-diagram-3-fill"></span>
                <h4>Planification</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </li>
            <li>
                <span class="bi bi-megaphone-fill"></span>
                <h4>Communication</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </li>
            <li>
                <span class="bi bi-person-lines-fill"></span>
                <h4>Suivi</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </li>
            <li>
                <span class="bi bi-hand-thumbs-up-fill"></span>
                <h4>Qualité</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </li>
        </ul>
      </div>
      <div className='landing_offres'>
          <h3 className='offre_titre'>Nos offres</h3>
          <div className='liste_offre'>
              <ul className='liste_contenu'>
                <li className='basi'>
                  <div className='content'>
                    <div className='test'>
                      <h5 className='isa'>Gestion Basic</h5>
                      <h6 className='roa'>0Ar/mois</h6>
                    </div>
                    <div className='checklist'>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-x-circle icon-danger'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-x-circle icon-danger'></span> Lorem Ipsum is simply dummy</p>
                      <del><p><span class='bi bi-x-circle icon-danger'></span> Lorem Ipsum is simply dummy</p></del>
                    </div>
                    <button className='blue-bg'>Commander</button>
                  </div>
                </li>
                <li className='pro'>
                  <div className='content'>
                    <div className='test'>
                      <h5 className='telo'>Gestion Pro</h5>
                      <h6 className='efatra'>5000Ar/mois</h6>
                    </div>
                    <div className='checklist'>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-x-circle icon-danger'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-x-circle icon-danger'></span> Lorem Ipsum is simply dummy</p>
                    </div>
                    <button className='gray-bg'>Commander</button>
                  </div>
                </li>
                <li className='prem'>
                  <div className='content'>
                    <div className='test'>
                      <h5 className='dimy'>Gestion Premium</h5>
                      <h6 className='enina'>10000Ar/mois</h6>
                    </div>
                    <div className='checklist'>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                      <p><span class='bi bi-check-circle icon-success'></span> Lorem Ipsum is simply dummy</p>
                    </div>
                    <button className='danger-bg'>Commander</button>
                  </div>
                </li>
              </ul>
          </div>
          <div className='landing_apropos' >
            <h4 className='prop_titre'>A propos</h4>
            <div className='landing_apro'>
                <div className='parun'>
                    <h5 className='plaM'>Plateforme Mestre</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting indust ry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <div className='prop_image'>
                  <img src='./image/landi.png' alt='landi'/>
                </div>
                <div className='pardeux'>
                    <h5 className='obj'>Objectif</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting indust ry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
          </div>
          <div className='feat'>
            <h4 className='feat_titre'>Features</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  industry's standard dummy text ever since the 1500s,</p>
            <div list_feat>
              <ul className='featu'>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                  <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
                <li>
                  <div className='feature_liste'>
                  <img src='./image/Group2.png' alt='grou' />
                    <h5 className='lorem'>Lorem Ipsum</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='contact_landing'>
              <div className='contact_1'>
                  <h3>Contact</h3>
                  <h5>Suivez-nous!</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting ind Lorem Ipsum has been the industt standard dummy text ever since t</p>
                    <span className='twite bi bi-twitter'></span>
                    <span className='insta bi bi-instagram'></span>
                    <span className='faceboo bi bi-facebook'></span>
                    <span className='linkd bi bi-linkedin'></span>
              </div>
              <div className='formulaire_contact'>
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="nom" className="form-label">Nom</label>
                          <input
                            type="text"
                            id="nom"
                            name="nom"
                            placeholder="Nom"
                            className="form-control"
                            value={formData.nom}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">Votre email</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Votre email"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="message" className="form-label">Votre message</label>
                          <textarea
                            id="message"
                            name="message"
                            placeholder="Votre message"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#014F77', marginBottom:'5%'}}>Envoyer</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Landing;
