import React, { useState, useEffect } from "react";
import "../Css/prodeveloppeur.css";
import NavDeveloppeur from "./NavDeveloppeur";
import Copyright from "../../Accueil/Js/Copyright";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap"; // Import de Modal et Button
import url from "../../url";

const Tachedeveloppeur = () => {
  const [taches, setTaches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const [taskStartTime, setTaskStartTime] = useState(null);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const columns = [
    {
      name: "Projet",
      selector: "tache_projet",
      sortable: true,
    },
    {
      name: "Tache",
      selector: "tache_nom",
      sortable: true,
    },
    {
      name: "Estimation",
      selector: "tache_estimation",
      sortable: true,
      cell: (row) => (
        <span>
          {row.tache_estimation}{" "}
          {row.tache_estimation === 1 ? "heure" : "heures"}
        </span>
      ),
    },
    {
      name: "Temps restant", // Nouvelle colonne pour le temps restant
      cell: (row) => {
        const tempsRestant = Math.max(0, row.tache_estimation_seconds - timer);
        const heuresRestantes = Math.floor(tempsRestant / 3600); // secondes en heures
        const minutesRestantes = Math.floor((tempsRestant % 3600) / 60); // secondes en minutes
        const secondesRestantes = tempsRestant % 60; // secondes restantes
        return `${heuresRestantes}h ${minutesRestantes}m ${secondesRestantes}s`;
      },
      sortable: true,
    },
    {
      name: "Réalisation",
      selector: "tache_realisation",
      sortable: true,
    },
    {
      name: "Date",
      selector: "tache_date_debut",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "tache_statut",
      sortable: true,
    },
    {
      name: "Pause",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "#014F77",
            marginRight: "7px",
          }}
        >
          <span className="bi bi-cup-hot"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Commencer/Terminer",
      cell: (row) => (
        <>
          <button
            className="btn btn-success"
            onClick={() => startTimer(row)}
            style={{ marginRight: "2%" }}
          >
            <span className="bi bi-power"></span>
          </button>
          <button className="btn btn-danger" onClick={() => startTimer(row)}>
            <span
              className="bi bi-skip-end-circle"
              style={{ fontSize: "18px" }}
            ></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const startTimer = (task) => {
    setSelectedTask(task);
    setIsTimerRunning(true);
    setTaskStartTime(new Date());
    setShowModal(true);
  };

  useEffect(() => {
    url
      .get("/tache/")
      .then((res) => {
        // Convertir le temps estimé de chaque tâche en secondes
        const tachesData = res.data.map((tache) => ({
          ...tache,
          tache_estimation_seconds: tache.tache_estimation * 3600, // heures en secondes
        }));
        setTaches(tachesData);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (isTimerRunning) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isTimerRunning]);

  const modifTache = (id, editedData) => {
    return new Promise((resolve, reject) => {
      url
        .put("/tache/" + id, {
          id: editedData.id,
          tache_statut: editedData.tache_statut,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
  }

  function padZero(value) {
    return value < 10 ? `0${value}` : value;
  }

  return (
    <div className="tachedev_conteneur">
      <div className="nav_tachedev">
        <NavDeveloppeur />
      </div>
      <div className="tachedev_table " style={{ paddingTop: "30px" }}>
        <h4 className="mes_projets" style={{ marginLeft: "1%" }}>
          Mes taches
        </h4>
        <div className="recherche_tachedev" style={{ marginLeft: "15px" }}>
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Rechercher..."
              style={{
                outline: "none",
                marginRight: "5px",
                borderRadius: "5px",
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                style={{ backgroundColor: "#014F77", color: "White" }}
              >
                <span className="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={taches}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>
      <Copyright />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Commencer la tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTask && (
            <div>
              <p>ID de la tâche sélectionnée : {selectedTask.id}</p>
              <p>
                Date et heure du début :{" "}
                {taskStartTime && taskStartTime.toLocaleString()}
              </p>
              <p>Temps écoulé : {formatTime(timer)}</p>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (selectedTask) {
                const editedData = {
                  ...selectedTask,
                  tache_statut: "en cours",
                };
                modifTache(editedData.id, editedData)
                  .then(() => {
                    const updatedTaches = taches.map((tache) =>
                      tache.id === editedData.id ? editedData : tache
                    );
                    setTaches(updatedTaches);
                    window.alert("Modification avec succès");
                    setShowModal(false);
                    startTimer();
                  })
                  .catch((error) => {
                    console.error(error);
                    window.alert(
                      "Une erreur est survenue lors de la modification."
                    );
                  });
              }
            }}
          >
            Commencer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Tachedeveloppeur;
