import React, { useState, useEffect } from "react";
import "../Css/Sidebar.css";

const Sidebar = ({ active }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = currentTime.toLocaleTimeString();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={`sidebar ${active ? "active" : ""}`}>
      <ul>
        <li className="temp" onClick={toggleModal}>
          <span className="bi bi-clock"></span>Temps: {formattedTime}
        </li>
        <li className="rapp">
          <span className="bi bi-file-check"></span>Rapport
        </li>
        <li className="deco" onClick={handleLogout}>
          <span className="bi bi-box-arrow-in-right"></span>Se déconnecter
        </li>
      </ul>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Date d'aujourd'hui avec l'heure actuelle:</p>
            <p>{formattedTime}</p>
            <button onClick={toggleModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
