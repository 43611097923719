import React, { useEffect, useState } from "react";
import NavbarMain from "./NavbarMain";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import url from "../../url";

const Accueil = () => {
  const [projets, setProjets] = useState([]);
  const [developpeurs, setDeveloppeurs] = useState([]);
  const [equipes, setEquipes] = useState([]);
  const [clients, setClients] = useState([]);

  const totalCharge = projets.reduce(
    (total, project) => total + project.projet_charge,
    0
  );

  const calculateTotalBudget = () => {
    let totalBudget = 0;
    projets.forEach((projet) => {
      // Assurez-vous que le champ 'projet_budget' est numérique
      const budget = parseFloat(projet.projet_budget);
      if (!isNaN(budget)) {
        totalBudget += budget;
      }
    });
    return totalBudget;
  };

  const totalBudget = calculateTotalBudget();
  useEffect(() => {
    url
      .get("/projet/")
      .then((res) => setProjets(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    url
      .get("/client/")
      .then((res) => setClients(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    url
      .get("/developpeur/")
      .then((res) => {
        setDeveloppeurs(res.data);
        return url.get("/equipe/");
      })
      .then((res) => {
        setEquipes(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const totalProjetCount = projets.length;
  const totalDeveloppeurCount = developpeurs.length;
  const totalEquipeCount = equipes.length;
  const totalClientCount = clients.length;

  return (
    <div className="accueil_conteneur">
      <NavbarMain />
      <div className="container mt-5">
        <Row>
          <Col md={4}>
            <Card
              className="text-center custom-card smaller-card"
              style={{
                backgroundColor: "white",
                fontSize: "0.8rem",
                color: "#014F77",
                borderColor: "#014F77",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total Projets</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalProjetCount}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className=" text-success custom-card text-center smaller-card"
              style={{
                backgroundColor: "white",
                fontSize: "0.8rem",
                borderColor: "green",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total Développeurs</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalDeveloppeurCount}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className=" text-danger custom-card text-center smaller-card"
              style={{
                fontSize: "0.8rem",
                backgroundColor: "white",
                borderColor: "red",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total Équipes</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalEquipeCount}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <Card
              className=" text-success custom-card text-center smaller-card"
              style={{
                backgroundColor: "white",
                fontSize: "0.8rem",
                borderColor: "green",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total Clients</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalClientCount}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className=" text-danger custom-card text-center smaller-card"
              style={{
                fontSize: "0.8rem",
                backgroundColor: "white",
                borderColor: "red",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total Revenus</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalBudget} Ar</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className="text-center custom-card smaller-card"
              style={{
                backgroundColor: "white",
                fontSize: "0.8rem",
                color: "#014F77",
                borderColor: "#014F77",
                width: "90%",
              }}
            >
              <Card.Body>
                <Card.Title
                  className="smaller-text"
                  style={{ fontSize: "1.1rem" }}
                >
                  <b>Total charges</b>
                </Card.Title>
                <Card.Text className="smaller-text">
                  <b>{totalCharge} Ar</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Accueil;
