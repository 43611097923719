import React, { useState, useRef, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../Css/Navbar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from './Sidebar';
import {Link } from 'react-router-dom';

const Navbar = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const sidebarRef = useRef(null);

  const handleSidebarToggle = () => {
    setSidebarActive(!sidebarActive);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarActive(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='navbar_conteneur'>
      <div className='navbar_menu'>
        <div className='logo_nav'>
          <img className='logov' src='./image/logo-b.png' alt='logo'></img>
        </div>
        <ul className='menu_contenu'>
        <Link to="/projets" style={{ textDecoration: 'none', color: 'white' }}>
        <li className='proj'><span class="bi bi-card-checklist"></span>Projets</li>
        </Link>
        <Link to="/taches" style={{ textDecoration: 'none', color: 'white' }}>
          <li className='tach'><span class="bi bi-list-task"></span>Taches</li>
        </Link>
        <Link to="/developpeur" style={{ textDecoration: 'none', color: 'white' }}>
          <li className='deve'><span class="bi bi-person-gear"></span>Developpeur</li>
        </Link>
        <Link to="/equipes" style={{ textDecoration: 'none', color: 'white' }}>
          <li className='equi'><span class="bi bi-people-fill"></span>Equipes</li>
        </Link>
        <Link to="/clients" style={{ textDecoration: 'none', color: 'white' }}>
          <li className='cli'><span class="bi bi-person-heart"></span>Clients</li>
        </Link>
          <li className='disg'><span class="bi bi-chat-dots"></span>Discussion générales</li>
          <li className='noti'><span class="bi bi-bell"></span></li>
          <li className='side' onClick={handleSidebarToggle}>
            {sidebarActive ? <span className="bi bi-chevron-double-right"></span> : <span className="bi bi-chevron-double-left"></span>}
          </li>
        </ul>
      </div>
      <Sidebar ref={sidebarRef} active={sidebarActive} />
    </div>
  )
}

export default Navbar;
