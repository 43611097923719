import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Css/navland.css";

const Navlanding = () => {
  return (
    <div>
      <Navbar className="custom-navbar" variant="dark" expand="lg">
        <Navbar.Brand>
          <div className="logo_blanche" style={{ marginLeft: "20%" }}>
            <img className="lolo" src="./image/logo-b.png" alt="log" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{ marginLeft: "50%", fontWeight: "bold" }}>
            <Nav.Link>Accueil</Nav.Link>
            <Nav.Link>Nos Offres</Nav.Link>
            <Nav.Link>A Propos</Nav.Link>
            <Nav.Link>
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "white" }}
              >
                Se Connecter
              </Link>
            </Nav.Link>
          </Nav>
          <Button variant="danger" style={{ marginLeft: "5%" }}>
            Contactez-nous
          </Button>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navlanding;
