import React, { useState, useEffect } from "react";
import "../Css/equipes.css";
import NavbarMain from "../../Accueil/Js/NavbarMain";
import Copyright from "../../Accueil/Js/Copyright";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import url from "../../url";

const EquipeDetailsModal = ({ show, onHide, equipe }) => {
  if (!equipe) {
    return null;
  }
  return (
    <Modal show={show} onHide={onHide} style={{ top: "7%", left: "0%" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span
            className="bi bi-bookmark-star-fill"
            style={{ color: "#014F77" }}
          />
          Détails de l'équipe
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Nom :</b> &nbsp;&nbsp;&nbsp;&nbsp;
            {equipe.equipe_nom}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Date de création : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {equipe.equipe_date_creation}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Chef de l'équipe : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {equipe.equipe_chef}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Membres : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {equipe.equipe_membres}
          </p>

          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Projets réalisés : </b>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Projets en cours : </b>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fermer
        </Button>
        <Button variant="success" onClick={onHide}>
          Pdf
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Equipes = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    equipe_nom: "",
    equipe_date_creation: "",
    equipe_membres: "",
    equipe_chef: "",
    equipe_specialites: "",
  });
  const [errors, setErrors] = useState({});
  const [developpeur, setDeveloppeurs] = useState([]);
  const [equipes, setEquipes] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleShowModal = () => setShowModal(true);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedEquipe, setSelectedEquipe] = useState(null);

  const handleShowDetailsModal = (equipe) => {
    setSelectedEquipe(equipe);
    setShowDetailsModal(true);
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!formData.equipe_nom.trim()) {
      newErrors.equipe_nom = "Le nom de l'équipe est requis.";
    }

    if (!formData.equipe_date_creation) {
      newErrors.equipe_date_creation =
        "La date de création de l'équipe est requise.";
    }
    if (!formData.equipe_membres.trim()) {
      newErrors.equipe_membres = "Le nombre de membres est requis.";
    }

    if (!formData.equipe_chef.trim()) {
      newErrors.equipe_chef = "Le nom du chef de l'équipe est requis.";
    }

    if (!formData.equipe_specialites.trim()) {
      newErrors.equipe_specialites = "La spécialité de l'équipe est requise.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Aucune erreur, continuer avec la soumission du formulaire
      url
        .post("/equipe", formData)
        .then((res) => {
          setEquipes([...equipes, res.data]);
          resetForm();
          handleCloseModal();
          afficheEquipe();
        })
        .catch((err) => console.log(err));
    }
  };
  const resetForm = () => {
    setFormData({
      equipe_nom: "",
      equipe_date_creation: "",
      equipe_membres: "",
      equipe_chef: "",
      equipe_specialites: "",
    });
    setErrors({});
  };
  const columns = [
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            color: "#014F77",
            marginRight: "7px",
          }}
          onClick={() => handleShowDetailsModal(row)}
        >
          <span class="bi bi-person-fill"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Chef de l'équipe",
      selector: "equipe_chef",
      sortable: true,
    },
    {
      name: "Nom de l'équipe",
      selector: "equipe_nom",
      sortable: true,
    },
    {
      name: "Date de creation",
      selector: "equipe_date_creation",
      sortable: true,
    },
    {
      name: "Membres",
      selector: "equipe_membres",
      sortable: true,
    },
    {
      name: "Spécialités",
      selector: "equipe_specialites",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => handleEditEquipe(row)}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-pencil-square"></span>
          </button>
          <button className="btn btn-danger" onClick={() => supEquipe(row.id)}>
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };

  const afficheEquipe = async () => {
    try {
      const developpeurResponse = await url.get("/developpeur/");
      const equipeResponse = await url.get("/equipe/");

      setDeveloppeurs(developpeurResponse.data);
      setEquipes(equipeResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    afficheEquipe();
  }, []);

  const supEquipe = (id) => {
    url
      .delete("/equipe/" + id)
      .then(() => {
        const updatedEquipes = equipes.filter((equipe) => equipe.id !== id);
        setEquipes(updatedEquipes);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const handleUpdateEquipe = (updatedData) => {
    const updatedIndex = equipes.findIndex(
      (equipe) => equipe.id === updatedData.id
    );

    if (updatedIndex !== -1) {
      const updatedEquipes = [...equipes];
      updatedEquipes[updatedIndex] = updatedData;
      setEquipes(updatedEquipes);
    }
  };

  const handleEditEquipe = (equipe) => {
    console.log("Editing equipe:", equipe);
    setClientToEdit({
      id: equipe.id,
      equipe_nom: equipe.equipe_nom,
      equipe_date_creation: equipe.equipe_date_creation,
      equipe_membres: equipe.equipe_membres,
      equipe_chef: equipe.equipe_chef,
      equipe_specialites: equipe.equipe_specialites,
    });
    setShowModifyModal(true);
  };

  const modifEquipe = (id, editedData) => {
    url
      .put("/equipe/" + id, {
        id: editedData.id,
        equipe_nom: editedData.equipe_nom,
        equipe_date_creation: editedData.equipe_date_creation,
        equipe_membres: editedData.equipe_membres,
        equipe_chef: editedData.equipe_chef,
        equipe_specialites: editedData.equipe_specialites,
      })
      .then((res) => {
        const updatedEquipes = equipes.map((equipe) =>
          equipe.id === id ? editedData : equipe
        );
        setEquipes(updatedEquipes);
        window.alert("Modification avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  const [equipeToEdit, setClientToEdit] = useState({
    equipe_nom: "",
    equipe_date_creation: "",
    equipe_membres: "",
    equipe_chef: "",
    equipe_specialites: "",
  });

  const saveModifiedEquipe = (modifiedData) => {
    handleUpdateEquipe(modifiedData);
  };

  const ModifierEquipeModal = ({ afficher, onHide, formData }) => {
    const [editedData, setEditedData] = useState(formData);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
    return (
      <Modal show={afficher} onHide={onHide} style={{ top: "6%", left: "0%" }}>
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Modifier les détails de l'équipe
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="from-group">
              <input
                type="hidden"
                name="id"
                className={`form-control`}
                value={editedData.id}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Chef d'equipe:</label>
              <select
                name="equipe_chef"
                value={editedData.equipe_chef}
                onChange={handleChange}
                className={`form-control ${errors.equipe_chef ? "error" : ""}`}
              >
                {developpeur.map((item) => (
                  <option key={item.id} value={item.developpeur_nom}>
                    {item.developpeur_nom}
                  </option>
                ))}
              </select>
              {errors.equipe_chef && (
                <span className="error_message">{errors.equipe_chef}</span>
              )}
            </div>
            <div className="from-group">
              <label>Nom de l'équipe:</label>
              <input
                type="text"
                name="equipe_nom"
                className={`form-control`}
                value={editedData.equipe_nom}
                onChange={handleChange}
              />
            </div>
            <div className="from-group">
              <label>Date de création de l'équipe:</label>
              <input
                type="date"
                name="equipe_date_creation"
                className={`form-control`}
                value={editedData.equipe_date_creation}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Membres:</label>
              <select
                name="equipe_membres"
                value={editedData.equipe_membres}
                onChange={handleChange}
                className={`form-control ${
                  errors.equipe_membres ? "error" : ""
                }`}
              >
                {developpeur.map((item) => (
                  <option key={item.id} value={item.developpeur_nom}>
                    {item.developpeur_nom}
                  </option>
                ))}
              </select>
              {errors.equipe_membres && (
                <span className="error_message">{errors.equipe_membres}</span>
              )}
            </div>
            <div className="from-group">
              <label>Spécialités :</label>
              <input
                type="text"
                name="equipe_specialites"
                className={`form-control`}
                value={editedData.equipe_specialites}
                onChange={handleChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={() => {
              modifEquipe(editedData.id, editedData);
              onHide();
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="equipe_conteneur">
      <div className="equipe_nav">
        <NavbarMain />
      </div>
      <div>
        <h5 className="equipe_titre">GESTION DES EQUIPES</h5>
      </div>
      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div className="ajout_equipe">
          <button
            className="btn btn-primary"
            onClick={handleShowModal}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-plus-lg"></span>Ajouter
          </button>
        </div>
        <div className="recherche_projet">
          <div className="input-group mb-3 custom-search">
            <input
              type="text"
              placeholder="Rechercher..."
              className="form-control"
              style={{
                borderRadius: "5px 0 0 5px",
                borderTop: "2px solid #ccc",
                borderBottom: "2px solid #ccc",
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Ajouter ici les détails de l'équipe
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="modal_projet">
              <label>Nom de l'équipe:</label>
              <input
                type="text"
                name="equipe_nom"
                className={`form-control ${errors.equipe_nom ? "error" : ""}`}
                value={formData.equipe_nom}
                onChange={handleChange}
              />
              {errors.equipe_nom && (
                <span className="error_message">{errors.equipe_nom}</span>
              )}
            </div>
            <div className="from-group">
              <label>Date de création de l'équipe:</label>
              <input
                type="date"
                name="equipe_date_creation"
                className={`form-control ${
                  errors.equipe_date_creation ? "error" : ""
                }`}
                value={formData.equipe_date_creation}
                onChange={handleChange}
              />
              {errors.equipe_date_creation && (
                <span className="error_message">
                  {errors.equipe_date_creation}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Membres:</label>
              <select
                name="equipe_membres"
                value={formData.equipe_membres}
                onChange={handleChange}
                className={`form-control ${
                  errors.equipe_membres ? "error" : ""
                }`}
              >
                <option value="">Veuillez sélectionner les membres</option>
                {developpeur.map((item) => (
                  <option key={item.id} value={item.developpeur_nom}>
                    {item.developpeur_nom}
                  </option>
                ))}
              </select>
              {errors.equipe_membres && (
                <span className="error_message">{errors.equipe_membres}</span>
              )}
            </div>
            <div className="form-group">
              <label>Chef d'equipe:</label>
              <select
                name="equipe_chef"
                value={formData.equipe_chef}
                onChange={handleChange}
                className={`form-control ${errors.equipe_chef ? "error" : ""}`}
              >
                <option value="">Veuillez sélectionner un chef d'equipe</option>
                {developpeur.map((item) => (
                  <option key={item.id} value={item.developpeur_nom}>
                    {item.developpeur_nom}
                  </option>
                ))}
              </select>
              {errors.equipe_chef && (
                <span className="error_message">{errors.equipe_chef}</span>
              )}
            </div>
            <div className="from-group">
              <label>Spécialités :</label>
              <input
                type="text"
                name="equipe_specialites"
                className={`form-control ${
                  errors.equipe_specialites ? "error" : ""
                }`}
                value={formData.equipe_specialites}
                onChange={handleChange}
              />
              {errors.equipe_specialites && (
                <span className="error_message">
                  {errors.equipe_specialites}
                </span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={handleSubmit}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="equipe_table" style={{ marginBottom: "3%" }}>
        <DataTable
          columns={columns}
          data={equipes}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />

        <ModifierEquipeModal
          afficher={showModifyModal}
          onHide={() => setShowModifyModal(false)}
          formData={equipeToEdit}
          onUpdate={handleUpdateEquipe}
          onSave={saveModifiedEquipe}
        />
      </div>
      <EquipeDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        equipe={selectedEquipe}
      />
      <div className="equipe_footer">
        <Copyright />
      </div>
    </div>
  );
};

export default Equipes;
