import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const MdpOublie = () => {
  const [email, setEmail] = useState("");

  const handleEnvoyerClick = () => {
    console.log("Email entré :", email);
  };

  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-7">
            <div
              className="image_auth"
              style={{ marginTop: "5vh", marginRight: "5vw" }}
            >
              <img
                className="mimi img-fluid"
                src="./image/passforgot.png"
                alt="oublie"
              ></img>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="card-body"
              style={{
                marginTop: "17vh",
                marginRight: "2vw",
                marginLeft: "2vw",
              }}
            >
              <h5>Vous avez oublié votre mot de passe?</h5>
              <form className="w-70">
                <p>Nous allons vous envoyer votre mot de passe par email</p>
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="Votre email"
                    className="form-control"
                    id="password"
                    name="password"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="d-grid gap-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-primary w-100 bg-014F77"
                    style={{
                      backgroundColor: "#014F77",
                      color: "white",
                      outline: "none",
                    }}
                    onClick={handleEnvoyerClick}
                  >
                    Envoyer
                  </button>
                </div>
              </form>
              <p>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  Retourner a la page de connexion
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MdpOublie;
