import React, { useState, useEffect } from "react";
import "../Css/developpeur.css";
import NavbarMain from "../../Accueil/Js/NavbarMain";
import Copyright from "../../Accueil/Js/Copyright";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import url from "../../url";
const { PDFDocument, rgb, StandardFonts } = require("pdf-lib");

const DeveloppeurDetailsModal = ({ show, onHide, developpeur }) => {
  if (!developpeur) {
    return null;
  }
  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { height } = page.getSize();
    const text = `Nom du développeur: ${developpeur.developpeur_nom}\nPrenoms du developpeur: ${developpeur.developpeur_prenoms}\nSalaire : ${developpeur.developpeur_salaire}\nDate de naissance : ${developpeur.developpeur_date_naissance}\nSpécialités : ${developpeur.developpeur_specialites}\nGrade : ${developpeur.developpeur_grade}`;

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;

    const x = 100;
    let y = height - 100;

    text.split("\n").forEach((line) => {
      page.drawText(line, {
        x,
        y,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      y -= fontSize + 5;
    });

    if (developpeur.developpeur_photo.toLowerCase().endsWith(".png")) {
      // Chargement de l'image
      const imageBytes = await fetch(
        `Upload/${developpeur.developpeur_photo}`
      ).then((res) => res.arrayBuffer());
      const image = await pdfDoc.embedPng(imageBytes);

      const imageWidth = 100;
      const imageHeight = 100;
      page.drawImage(image, {
        x: 50,
        y: height - 150,
        width: imageWidth,
        height: imageHeight,
      });
    } else {
      console.error("Le fichier n'est pas un PNG valide.");
      // Gérez l'erreur ici
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl);
  };

  return (
    <Modal show={show} onHide={onHide} style={{ top: "7%", left: "0%" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span
            className="bi bi-bookmark-star-fill"
            style={{ color: "#014F77" }}
          />
          Détails la developpeur
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            src={"Upload/" + developpeur.developpeur_photo}
            alt="pdp"
            style={{
              width: "30%",
              marginBottom: "3%",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          ></img>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Nom :</b> &nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_nom}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Prénoms: </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_prenoms}
          </p>
          <a
            href={"Upload/" + developpeur.developpeur_cv}
            download={developpeur.developpeur_cv}
            style={{
              width: "30%",
              marginBottom: "3%",
              borderRadius: "50%",
              overflow: "hidden",
              display: "block",
              textAlign: "center",
            }}
          >
            <img
              src={"Upload/" + developpeur.developpeur_cv}
              alt="cv"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </a>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Salaire : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_salaire}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Date de naissance : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_date_naissance}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Spécialités : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_specialites}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Grade : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {developpeur.developpeur_grade}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Projets réalisés : </b>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Projets en cours : </b>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fermer
        </Button>
        <Button variant="success" onClick={generatePDF}>
          Pdf
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Developpeur = () => {
  const [showModal, setShowModal] = useState(false);
  const [developpeur_photo, setDeveloppeurPhoto] = useState(null);
  const [developpeur_cv, setDeveloppeurCV] = useState(null);
  const [developpeur_nom, setDeveloppeurNom] = useState("");
  const [developpeur_prenoms, setDeveloppeurPrenoms] = useState("");
  const [developpeur_salaire, setDeveloppeurSalaire] = useState("");
  const [developpeur_date_naissance, setDeveloppeurDateNaissance] =
    useState("");
  const [developpeur_specialites, setDeveloppeurSpecialites] = useState("");
  const [developpeur_grade, setDeveloppeurGrade] = useState("");
  const [formData, setFormData] = useState({
    developpeur_photo: "",
    developpeur_nom: "",
    developpeur_prenoms: "",
    developpeur_salaire: "",
    developpeur_date_naissance: "",
    developpeur_specialites: "",
    developpeur_cv: "",
    developpeur_grade: "",
  });

  const [errors, setErrors] = useState({});
  const [developpeurs, setDeveloppeurs] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleShowModal = () => setShowModal(true);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDeveloppeur, setSelectedDeveloppeur] = useState(null);

  const handleShowDetailsModal = (developpeur) => {
    setSelectedDeveloppeur(developpeur);
    setShowDetailsModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!developpeur_photo) {
      newErrors.developpeur_photo = "Le photo du développeur est requis.";
    }
    if (!developpeur_nom) {
      newErrors.developpeur_nom = "Le nom du développeur est requis.";
    }

    if (!developpeur_prenoms) {
      newErrors.developpeur_prenoms = "Les prénoms du développeur sont requis.";
    }

    // Validation pour le salaire
    if (!developpeur_salaire) {
      newErrors.developpeur_salaire = "Le salaire du développeur est requis.";
    } else if (isNaN(developpeur_salaire) || Number(developpeur_salaire) <= 0) {
      newErrors.developpeur_salaire = "Le salaire doit être un nombre positif.";
    }

    // Validation pour la date de naissance
    if (!developpeur_date_naissance) {
      newErrors.developpeur_date_naissance =
        "La date de naissance du développeur est requise.";
    }

    // Validation pour la spécialité
    if (!developpeur_specialites) {
      newErrors.developpeur_specialites =
        "La spécialité du développeur est requise.";
    }

    if (!developpeur_cv) {
      newErrors.developpeur_cv = "Le CV du développeur est requis.";
    }

    // Validation pour le grade
    if (!developpeur_grade) {
      newErrors.developpeur_grade = "Le grade du développeur est requis.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Aucune erreur, continuer avec la soumission du formulaire
      const formdata = new FormData();
      formdata.append("developpeur_photo", developpeur_photo);
      formdata.append("developpeur_nom", developpeur_nom);
      formdata.append("developpeur_prenoms", developpeur_prenoms);
      formdata.append("developpeur_cv", developpeur_cv);
      formdata.append("developpeur_grade", developpeur_grade);
      formdata.append("developpeur_specialites", developpeur_specialites);
      formdata.append("developpeur_date_naissance", developpeur_date_naissance);
      formdata.append("developpeur_salaire", developpeur_salaire);

      url
        .post("/developpeur", formdata)
        .then((res) => {
          setDeveloppeurs([...developpeurs, res.data]);
          resetForm();
          handleCloseModal();
          afficheDeveloppeur();
        })
        .catch((err) => console.log(err));
    }
  };

  const resetForm = () => {
    setFormData({
      developpeur_photo: "",
      developpeur_nom: "",
      developpeur_prenoms: "",
      developpeur_salaire: "",
      developpeur_date_naissance: "",
      developpeur_specialites: "",
      developpeur_cv: "",
      developpeur_grade: "",
    });
    setErrors({});
  };

  const columns = [
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            color: "#014F77",
            marginRight: "7px",
          }}
          onClick={() => handleShowDetailsModal(row)}
        >
          <span class="bi bi-person-fill"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Nom",
      selector: "developpeur_nom",
      sortable: true,
    },
    {
      name: "Prenoms",
      selector: "developpeur_prenoms",
      sortable: true,
    },
    {
      name: "Spécialités",
      selector: "developpeur_specialites",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "developpeur_grade",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => handleEditDeveloppeur(row)}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-pencil-square"></span>
          </button>
          <button
            className="btn btn-danger"
            onClick={() => supDeveloppeur(row.id)}
          >
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };

  const afficheDeveloppeur = async () => {
    url
      .get("/developpeur/")
      .then((res) => setDeveloppeurs(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    afficheDeveloppeur();
  }, []);
  const supDeveloppeur = (id) => {
    url
      .delete("/developpeur/" + id)
      .then(() => {
        const updatedDeveloppeurs = developpeurs.filter(
          (developpeur) => developpeur.id !== id
        );
        setDeveloppeurs(updatedDeveloppeurs);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const handleUpdateDeveloppeur = (updatedData) => {
    const updatedIndex = developpeurs.findIndex(
      (developpeur) => developpeur.id === updatedData.id
    );

    if (updatedIndex !== -1) {
      const updatedClients = [...developpeurs];
      updatedClients[updatedIndex] = updatedData;
      setDeveloppeurs(updatedClients);
    }
  };
  const handleEditDeveloppeur = (developpeur) => {
    console.log("Editing developpeur:", developpeur);
    setDeveloppeurToEdit({
      id: developpeur.id,
      developpeur_photo: developpeur.developpeur_photo,
      developpeur_nom: developpeur.developpeur_nom,
      developpeur_prenoms: developpeur.developpeur_prenoms,
      developpeur_salaire: developpeur.developpeur_salaire,
      developpeur_date_naissance: developpeur.developpeur_date_naissance,
      developpeur_specialites: developpeur.developpeur_specialites,
      developpeur_cv: developpeur.developpeur_cv,
      developpeur_grade: developpeur.developpeur_grade,
    });
    setShowModifyModal(true);
  };

  const modifDeveloppeur = (id, editedData) => {
    url
      .put("/developpeur/" + id, {
        id: editedData.id,
        developpeur_photo: editedData.developpeur_photo,
        developpeur_nom: editedData.developpeur_nom,
        developpeur_prenoms: editedData.developpeur_prenoms,
        developpeur_salaire: editedData.developpeur_salaire,
        developpeur_date_naissance: editedData.developpeur_date_naissance,
        developpeur_specialites: editedData.developpeur_specialites,
        developpeur_grade: editedData.developpeur_grade,
      })
      .then((res) => {
        const updatedDeveloppeurs = developpeurs.map((developpeur) =>
          developpeur.id === id ? editedData : developpeur
        );
        setDeveloppeurs(updatedDeveloppeurs);
        window.alert("Modification avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  const [developpeurToEdit, setDeveloppeurToEdit] = useState({
    developpeur_photo: "",
    developpeur_nom: "",
    developpeur_prenoms: "",
    developpeur_salaire: "",
    developpeur_date_naissance: "",
    developpeur_specialites: "",
    developpeur_grade: "",
  });

  const saveModifiedDeveloppeur = (modifiedData) => {
    handleUpdateDeveloppeur(modifiedData);
  };

  const ModifyDeveloppeurModal = ({ show, onHide, formData }) => {
    const [editedData, setEditedData] = useState(formData);
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
    return (
      <Modal show={show} onHide={onHide} style={{ top: "6%", left: "0%" }}>
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Modifier les détails du développeur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="from-group">
              <label>Nom :</label>
              <input
                type="text"
                name="developpeur_nom"
                className={`form-control ${
                  errors.developpeur_nom ? "error" : ""
                }`}
                value={editedData.developpeur_nom}
                onChange={handleChange}
              />
              {errors.developpeur_nom && (
                <span className="error_message">{errors.developpeur_nom}</span>
              )}
            </div>
            <div className="from-group">
              <label>Prénoms:</label>
              <input
                type="text"
                name="developpeur_prenoms"
                className={`form-control ${
                  errors.developpeur_prenoms ? "error" : ""
                }`}
                value={editedData.developpeur_prenoms}
                onChange={handleChange}
              />
              {errors.developpeur_prenoms && (
                <span className="error_message">
                  {errors.developpeur_prenoms}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Salaire :</label>
              <input
                type="number"
                name="developpeur_salaire"
                className={`form-control ${
                  errors.developpeur_salaire ? "error" : ""
                }`}
                value={editedData.developpeur_salaire}
                onChange={handleChange}
              />
              {errors.developpeur_salaire && (
                <span className="error_message">
                  {errors.developpeur_salaire}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Date de naissance:</label>
              <input
                type="date"
                name="developpeur_date_naissance"
                className={`form-control ${
                  errors.developpeur_date_naissance ? "error" : ""
                }`}
                value={editedData.developpeur_date_naissance}
                onChange={handleChange}
              />
              {errors.developpeur_date_naissance && (
                <span className="error_message">
                  {errors.developpeur_date_naissance}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Spécialités :</label>
              <input
                type="text"
                name="developpeur_specialites"
                className={`form-control ${
                  errors.developpeur_specialites ? "error" : ""
                }`}
                value={editedData.developpeur_specialites}
                onChange={handleChange}
              />
              {errors.developpeur_specialites && (
                <span className="error_message">
                  {errors.developpeur_specialites}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Grade:</label>
              <input
                type="text"
                name="developpeur_grade"
                className={`form-control ${
                  errors.developpeur_grade ? "error" : ""
                }`}
                value={editedData.developpeur_grade}
                onChange={handleChange}
              />
              {errors.developpeur_grade && (
                <span className="error_message">
                  {errors.developpeur_grade}
                </span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={() => {
              modifDeveloppeur(editedData.id, editedData);
              onHide();
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <div className="dev_conteneur">
      <div className="dev_nav">
        <NavbarMain />
      </div>
      <div>
        <h5 className="dev_titre">GESTION DES DEVELOPPEURS</h5>
      </div>
      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div className="ajout_equipe">
          <button
            className="btn btn-primary"
            onClick={handleShowModal}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-plus-lg"></span>Ajouter
          </button>
        </div>
        <div className="recherche_projet">
          <div className="input-group mb-3 custom-search">
            <input
              type="text"
              placeholder="Rechercher..."
              className="form-control"
              style={{
                borderRadius: "5px 0 0 5px",
                borderTop: "2px solid #ccc",
                borderBottom: "2px solid #ccc",
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Ajouter ici les détails de developpeur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
              <label>Photo :</label>
              <input
                type="file"
                name="developpeur_photo"
                accept="image/*"
                className={`form-control ${
                  errors.developpeur_photo ? "error" : ""
                }`}
                onChange={(e) => {
                  setDeveloppeurPhoto(e.target.files[0]);
                }}
              />
            </div>
            <div className="modal_dev">
              <label>Nom :</label>
              <input
                type="text"
                name="developpeur_nom"
                className={`form-control ${
                  errors.developpeur_nom ? "error" : ""
                }`}
                value={developpeur_nom}
                onChange={(e) => {
                  setDeveloppeurNom(e.target.value);
                }}
              />
              {errors.developpeur_nom && (
                <span className="error_message">{errors.developpeur_nom}</span>
              )}
            </div>
            <div className="from-group">
              <label>Prénoms:</label>
              <input
                type="text"
                name="developpeur_prenoms"
                className={`form-control ${
                  errors.developpeur_prenoms ? "error" : ""
                }`}
                value={developpeur_prenoms}
                onChange={(e) => {
                  setDeveloppeurPrenoms(e.target.value);
                }}
              />
              {errors.developpeur_prenoms && (
                <span className="error_message">
                  {errors.developpeur_prenoms}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Salaire :</label>
              <input
                type="number"
                name="developpeur_salaire"
                className={`form-control ${
                  errors.developpeur_salaire ? "error" : ""
                }`}
                value={developpeur_salaire}
                onChange={(e) => {
                  setDeveloppeurSalaire(e.target.value);
                }}
              />
              {errors.developpeur_salaire && (
                <span className="error_message">
                  {errors.developpeur_salaire}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Date de naissance:</label>
              <input
                type="date"
                name="developpeur_date_naissance"
                className={`form-control ${
                  errors.developpeur_date_naissance ? "error" : ""
                }`}
                value={developpeur_date_naissance}
                onChange={(e) => {
                  setDeveloppeurDateNaissance(e.target.value);
                }}
              />
              {errors.developpeur_date_naissance && (
                <span className="error_message">
                  {errors.developpeur_date_naissance}
                </span>
              )}
            </div>
            <div className="from-group">
              <label>Spécialités :</label>
              <input
                type="text"
                name="developpeur_specialites"
                className={`form-control ${
                  errors.developpeur_specialites ? "error" : ""
                }`}
                value={developpeur_specialites}
                onChange={(e) => {
                  setDeveloppeurSpecialites(e.target.value);
                }}
              />
              {errors.developpeur_specialites && (
                <span className="error_message">
                  {errors.developpeur_specialites}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>CV :</label>
              <input
                type="file"
                name="developpeur_cv"
                className={`form-control ${
                  errors.developpeur_cv ? "error" : ""
                }`}
                onChange={(e) => {
                  setDeveloppeurCV(e.target.files[0]);
                }}
              />
            </div>
            <div className="from-group">
              <label>Grade:</label>
              <input
                type="text"
                name="developpeur_grade"
                className={`form-control ${
                  errors.developpeur_grade ? "error" : ""
                }`}
                value={developpeur_grade}
                onChange={(e) => {
                  setDeveloppeurGrade(e.target.value);
                }}
              />
              {errors.developpeur_grade && (
                <span className="error_message">
                  {errors.developpeur_grade}
                </span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            type="submit"
            onClick={handleSubmit}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="dev_table" style={{ marginBottom: "3%" }}>
        <DataTable
          columns={columns}
          data={developpeurs}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>
      <ModifyDeveloppeurModal
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        formData={developpeurToEdit}
        onUpdate={handleUpdateDeveloppeur}
        onSave={saveModifiedDeveloppeur}
      />
      <DeveloppeurDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        developpeur={selectedDeveloppeur}
      />
      <div className="footer_taches">
        <Copyright />
      </div>
      <div className="dev_footer">
        <Copyright />
      </div>
    </div>
  );
};

export default Developpeur;
