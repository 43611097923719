import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import url from "../../url";

const Inscription = () => {
  const [formData, setFormData] = useState({
    utilisateur_type: "",
    utilisateur_nom: "",
    utilisateur_email: "",
    mot_de_passe: "",
    mot_de_confirmation: "",
  });

  const [error, setError] = useState("");
  const [inscriptions, setInscriptions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.utilisateur_type.trim() ||
      !formData.utilisateur_nom.trim() ||
      !formData.utilisateur_email.trim() ||
      !formData.mot_de_passe.trim() ||
      !formData.mot_de_confirmation.trim()
    ) {
      setError("Tous les champs sont obligatoires");
    } else if (formData.mot_de_passe !== formData.mot_de_confirmation) {
      setError("Les mots de passe ne correspondent pas");
    } else {
      // Aucune erreur, continuer avec la soumission du formulaire
      url
        .post("/inscription", formData)
        .then((res) => {
          setInscriptions([...inscriptions, res.data]);
          Notify();
          setError(""); // Réinitialiser les erreurs après un succès
          // Réinitialiser le formulaire avec des valeurs vides
          setFormData({
            utilisateur_type: "",
            utilisateur_nom: "",
            utilisateur_email: "",
            mot_de_passe: "",
            mot_de_confirmation: "",
          });
        })
        .catch((err) => {
          console.error(err);
          setError("Une erreur est survenue lors de l'inscription");
        });
    }
  };
  const Notify = () => {
    toast(" Inscription réussie! ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleWindowResize = () => {
    const imageElement = document.querySelector(".image_inscri img");
    if (imageElement) {
      if (window.innerWidth < 768) {
        imageElement.style.display = "none";
      } else {
        imageElement.style.display = "block";
      }
    }
  };

  window.addEventListener("resize", handleWindowResize);

  return (
    <div className="login_conteneur" style={{ marginTop: "5vh" }}>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-7">
            <div
              className="image_inscri"
              style={{ marginTop: "5vh", marginRight: "5vw" }}
            >
              <img
                className="mimi img-fluid"
                src="./image/tito.png"
                alt="inscription"
                style={{ width: "80%", marginTop: "5%" }}
              ></img>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="card-body"
              style={{ marginTop: "5vh", marginRight: "5vw" }}
            >
              <h3 style={{ marginLeft: "-70%", marginBottom: "10%" }}>
                Inscrivez-vous!
              </h3>
              <form className="w-70" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <select
                    className="form-select"
                    id="type"
                    name="utilisateur_type"
                    value={formData.utilisateur_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Sélectionnez un type</option>
                    <option value="Developpeur">Developpeur</option>
                    <option value="Client">Client</option>
                  </select>
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Nom d'utilisateur"
                    type="text"
                    className="form-control"
                    id="utilisateur_nom"
                    name="utilisateur_nom"
                    value={formData.utilisateur_nom}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Votre email"
                    type="email"
                    className="form-control"
                    id="email"
                    name="utilisateur_email"
                    value={formData.utilisateur_email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Mot de passe"
                    type="password"
                    className="form-control"
                    id="mot_de_passe"
                    name="mot_de_passe"
                    value={formData.mot_de_passe}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    placeholder="Confirmation Mot de passe"
                    type="password"
                    className="form-control"
                    id="mot_de_confirmation"
                    name="mot_de_confirmation"
                    value={formData.mot_de_confirmation}
                    onChange={handleChange}
                    required
                  />
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="d-grid gap-4 mb-4">
                  <button
                    type="submit"
                    onClick={Notify}
                    className="btn btn-primary w-100 bg-014F77"
                    style={{
                      backgroundColor: "#014F77",
                      color: "white",
                      outline: "none",
                    }}
                  >
                    S'inscrire
                  </button>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <button type="button" className="btn btn-danger w-100">
                      Annuler
                    </button>
                  </Link>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inscription;
