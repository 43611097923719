import React, { useState, useEffect } from "react";
import "../Css/prodeveloppeur.css";
import NavDeveloppeur from "./NavDeveloppeur";
import Copyright from "../../Accueil/Js/Copyright";
import DataTable from "react-data-table-component";
import url from "../../url";

const Prodeveloppeur = () => {
  const [projets, setProjets] = useState([]);

  const columns = [
    {
      name: "Nom du projet",
      selector: "projet_nom",
      sortable: true,
    },
    {
      name: "Chef du projet",
      selector: "projet_chef",
      sortable: true,
    },
    {
      name: "Equipes assignées",
      selector: "projet_equipe",
      sortable: true,
    },
    {
      name: "Date de début",
      selector: "projet_date_debut",
      sortable: true,
    },
    {
      name: "Date de fin",
      selector: "projet_date_fin",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "projet_statut",
      sortable: true,
    },
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "#014F77",
            marginRight: "7px",
          }}
        >
          <span className="bi bi-person"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="btn btn-danger">
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };

  useEffect(() => {
    url
      .get("/projet/")
      .then((res) => setProjets(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="prodev_conteneur">
      <div className="navdev">
        <NavDeveloppeur />
      </div>
      <div className="prodev_table" style={{ paddingTop: "30px" }}>
        <h4 className="mes_projets" style={{ marginLeft: "1%" }}>
          Mes projets
        </h4>
        <div className="recherche_tachedev" style={{ marginLeft: "15px" }}>
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Rechercher..."
              style={{
                outline: "none",
                marginRight: "5px",
                borderRadius: "5px",
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                style={{ backgroundColor: "#014F77", color: "White" }}
              >
                <span className="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={projets}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>
      <Copyright />
    </div>
  );
};

export default Prodeveloppeur;
