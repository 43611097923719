import React, { useState, useEffect } from "react";
import NavDeveloppeur from "./NavDeveloppeur";
import Copyright from "../../Accueil/Js/Copyright";
import DataTable from "react-data-table-component";
import url from "../../url";

const Equipedeveloppeur = () => {
  const [equipes, setEquipes] = useState([]);

  const columns = [
    {
      name: "Nom de l'équipe",
      selector: "equipe_nom",
      sortable: true,
    },
    {
      name: "Date de création",
      selector: "equipe_date_creation",
      sortable: true,
    },
    {
      name: "Chef de l'équipe",
      selector: "equipe_chef",
      sortable: true,
    },
    {
      name: "Membres",
      selector: "equipe_membres",
      sortable: true,
    },
    {
      name: "Spécialités",
      selector: "equipe_specialites",
      sortable: true,
    },
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "#014F77",
            marginRight: "7px",
          }}
        >
          <span class="bi bi-person"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="btn btn-danger">
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];
  useEffect(() => {
    url
      .get("/equipe/")
      .then((res) => setEquipes(res.data))
      .catch((err) => console.log(err));
  }, []);

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };
  return (
    <div className="equipedev_conteneur">
      <div className="equipedev_navbar">
        <NavDeveloppeur />
      </div>
      <div className="equipedev_table" style={{ paddingTop: "30px" }}>
        <h4 className="mes_projets" style={{ marginLeft: "1%" }}>
          Mon équipe
        </h4>
        <div className="recherche_equipedev" style={{ marginLeft: "15px" }}>
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Rechercher..."
              style={{
                outline: "none",
                marginRight: "5px",
                borderRadius: "5px",
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                style={{ backgroundColor: "#014F77", color: "White" }}
              >
                <span className="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={equipes}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 100]}
        />
      </div>
      <Copyright />
    </div>
  );
};

export default Equipedeveloppeur;
