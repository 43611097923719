import React, { useState, useEffect } from "react";
import NavbarMain from "../../Accueil/Js/NavbarMain";
import "../Css/taches.css";
import Copyright from "../../Accueil/Js/Copyright";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import url from "../../url";
const { PDFDocument, rgb, StandardFonts } = require("pdf-lib");
const TacheDetailsModal = ({ show, onHide, tache }) => {
  if (!tache) {
    return null;
  }
  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { height } = page.getSize();
    const text = `Nom du projet: ${tache.tache_projet}\nNom de la tâche: ${tache.tache_nom}\nDéscription : ${tache.tache_description}\nPriorité de la tâche : ${tache.tache_priorite}\nDate de réalisation : ${tache.tache_date_debut}\nDeveloppeur chargé : ${tache.tache_developpeur}\nEstimation de la réalisation : ${tache.tache_estimation} heure(s)\nStatut : ${tache.tache_statut}\nDurée de la realisation : ${tache.tache_realisation}`;

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;

    const x = 100;
    let y = height - 100;

    text.split("\n").forEach((line) => {
      page.drawText(line, {
        x,
        y,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      y -= fontSize + 5;
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl);
  };

  return (
    <Modal show={show} onHide={onHide} style={{ top: "7%", left: "0%" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span
            className="bi bi-bookmark-star-fill"
            style={{ color: "#014F77" }}
          />
          Détails de la tache
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Nom du projet:</b> &nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_projet}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Nom de la tache: </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_nom}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Déscription : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_description}
          </p>
          {tache.tache_priorite && (
            <p>
              <span className="bi bi-stars" style={{ color: "red" }} />
              <b>Priorité de la tâche :</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {tache.tache_priorite}
            </p>
          )}

          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Date de realisation : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_date_debut}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Developpeur chargé : </b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_developpeur}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Estimation de la réalisation :</b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_estimation} heure
            {tache.tache_estimation !== 1 ? "s" : ""}
          </p>

          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Heure de debut de la tache : </b>&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Heure de realisation :</b>&nbsp;&nbsp; &nbsp; &nbsp;
            {tache.tache_realisation}
          </p>
          <p>
            <span className="bi bi-stars" style={{ color: "red" }} />
            <b>Statut de la tache :</b>&nbsp;&nbsp;&nbsp;&nbsp;
            {tache.tache_statut}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fermer
        </Button>
        <Button variant="success" onClick={generatePDF}>
          Pdf
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Taches = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    tache_projet: "",
    tache_nom: "",
    tache_description: "",
    tache_priorite: "",
    tache_date_debut: "",
    tache_realisation: "",
    tache_developpeur: "",
  });

  const [errors, setErrors] = useState({});
  const [projets, setProjets] = useState([]);
  const [taches, setTaches] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [developpeur, setDeveloppeurs] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false);
  };
  const handleShowModal = () => setShowModal(true);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedTache, setSelectedTache] = useState(null);

  const handleShowDetailsModal = (tache) => {
    setSelectedTache(tache);
    setShowDetailsModal(true);
  };

  const handleAjouterClick = () => {
    const newErrors = {};

    if (formData.tache_nom === "") {
      newErrors.tache_nom = "Le nom de la tâche est requis.";
    }

    if (formData.tache_date_debut === "") {
      newErrors.tache_date_debut = "La date de début est requise.";
    }
    if (formData.tache_description === "") {
      newErrors.tache_description = "La déscription de la tache est requise.";
    }
    if (formData.tache_priorite === "") {
      newErrors.tache_priorite = "La priorité de la tache est requise.";
    }

    // Vérification pour le champ "tache_projet"
    if (formData.tache_projet === "") {
      newErrors.tache_projet = "Veuillez sélectionner un projet.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      url
        .post("/tache", formData)
        .then((res) => {
          setTaches([...taches, res.data]);
          resetForm();
          handleCloseModal();
          afficheTache();
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    url
      .get("/developpeur/")
      .then((res) => setDeveloppeurs(res.data))
      .catch((err) => console.log(err));
  }, []);

  const resetForm = () => {
    setFormData({
      tache_projet: "",
      tache_nom: "",
      tache_description: "",
      tache_priorite: "",
      tache_date_debut: "",
      tache_estimation: "",
      tache_realisation: "",
      tache_developpeur: "",
    });
    setErrors({});
  };
  const columns = [
    {
      name: "Détails",
      cell: (row) => (
        <button
          className="btn btn-primary"
          style={{
            textDecoration: "none",
            backgroundColor: "white",
            color: "#014F77",
            marginRight: "7px",
          }}
          onClick={() => handleShowDetailsModal(row)}
        >
          <span className="bi bi-clipboard-plus-fill"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Projet",
      selector: "tache_projet",
      sortable: true,
    },
    {
      name: "Nom",
      selector: "tache_nom",
      sortable: true,
    },
    {
      name: "Priorité",
      selector: "tache_priorite",
      sortable: true,
    },

    {
      name: "Estimation",
      selector: "tache_estimation",
      sortable: true,
      cell: (row) => (
        <span>
          {row.tache_estimation}{" "}
          {row.tache_estimation === 1 ? "heure" : "heures"}
        </span>
      ),
    },

    {
      name: "Début",
      selector: "tache_date_debut",
      sortable: true,
    },
    {
      name: "Réalisation",
      selector: "tache_realisation",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "tache_statut",
      sortable: true,
    },
    {
      name: "Assignation",
      cell: (row) => (
        <button
          className="btn btn-success"
          style={{ textDecoration: "none", marginRight: "7px" }}
          onClick={() => handleAssignTache(row)}
        >
          <span className="bi bi-person-add"></span>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => handleEditTache(row)}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-pencil-square"></span>
          </button>
          <button className="btn btn-danger" onClick={() => supTache(row.id)}>
            <span className="bi bi-trash3"></span>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const priorityCellStyle = (row) => {
    let cellStyle = {};

    if (row.tache_priorite.toLowerCase() === "elevee") {
      cellStyle.color = "red";
    } else if (row.tache_priorite.toLowerCase() === "moyenne") {
      cellStyle.color = "#014f77";
    } else if (row.tache_priorite.toLowerCase() === "faible") {
      cellStyle.color = "green";
    }

    return cellStyle;
  };

  const customStyles = {
    headRow: {
      style: {
        border: "2px solid #ccc",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingLeft: "2px",
        marginLeft: "10px",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        padding: "2px",
        marginLeft: "10px",
      },
    },
  };

  const afficheTache = async () => {
    try {
      const projetResponse = await url.get("/projet/");
      const tacheResponse = await url.get("/tache/");

      setProjets(projetResponse.data);
      setTaches(tacheResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    afficheTache();
  }, []);

  const supTache = (id) => {
    url
      .delete("/tache/" + id)
      .then(() => {
        const updatedTaches = taches.filter((tache) => tache.id !== id);
        setTaches(updatedTaches);

        window.alert("Suppression avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la suppression.");
      });
  };

  const handleUpdateTache = (updatedData) => {
    const updatedIndex = taches.findIndex(
      (tache) => tache.id === updatedData.id
    );

    if (updatedIndex !== -1) {
      const updatedTaches = [...taches];
      updatedTaches[updatedIndex] = updatedData;
      setTaches(updatedTaches);
    }
  };

  const handleEditTache = (tache) => {
    console.log("Editing tache:", tache);
    setTacheToEdit({
      id: tache.id,
      tache_projet: tache.tache_projet,
      tache_nom: tache.tache_nom,
      tache_description: tache.tache_description,
      tache_priorite: tache.tache_priorite,
      tache_date_debut: tache.tache_date_debut,
      tache_estimation: tache.tache_estimation,
    });
    setShowModifyModal(true);
  };

  const handleAssignTache = (tache) => {
    console.log("Editing tache:", tache);
    setTacheToEdit({
      id: tache.id,
      tache_developpeur: tache.tache_developpeur,
    });
    setShowAssignModal(true);
  };

  const modifTache = (id, editedData) => {
    url
      .put("/tache/" + id, {
        id: editedData.id,
        tache_projet: editedData.tache_projet,
        tache_nom: editedData.tache_nom,
        tache_description: editedData.tache_description,
        tache_priorite: editedData.tache_priorite,
        tache_date_debut: editedData.tache_contact,
        tache_estimation: editedData.tache_contact,
      })
      .then((res) => {
        const updatedTaches = taches.map((tache) =>
          tache.id === id ? editedData : tache
        );
        setTaches(updatedTaches);
        window.alert("Modification avec succès");
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };

  const assignTache = (id, editedData) => {
    url
      .put("/tache/" + id, {
        id: editedData.id,
        tache_developpeur: editedData.tache_developpeur,
      })
      .then((res) => {
        const updatedTaches = taches.map((tache) =>
          tache.id === id ? editedData : tache
        );
        setTaches(updatedTaches);
        window.alert("Modification avec succès");
        afficheTache();
      })
      .catch((error) => {
        console.error(error);
        window.alert("Une erreur est survenue lors de la modification.");
      });
  };
  const [tacheToEdit, setTacheToEdit] = useState({
    tache_nom: "",
    tache_projet: "",
    tache_description: "",
    tache_priorite: "",
    tache_date_debut: "",
    tache_estimation: "",
    tache_developpeur: "",
  });

  const saveModifiedTache = (modifiedData) => {
    handleUpdateTache(modifiedData);
  };

  const AssignTacheModal = ({ show, onHide, formData, onSave }) => {
    const [editedData, setEditedData] = useState({
      id: formData.id,
      tache_developpeur: [],
    });

    const handleChange = (e) => {
      const { name, value, checked } = e.target;

      if (name === "tache_developpeur") {
        if (checked) {
          setEditedData({
            ...editedData,
            tache_developpeur: [...editedData.tache_developpeur, value],
          });
        } else {
          setEditedData({
            ...editedData,
            tache_developpeur: editedData.tache_developpeur.filter(
              (devNom) => devNom !== value
            ),
          });
        }
      } else {
        setEditedData({
          ...editedData,
          [name]: value,
        });
      }
    };

    const handleAssignClick = () => {
      const tacheDeveloppeurString = Array.isArray(editedData.tache_developpeur)
        ? editedData.tache_developpeur.join(",")
        : "";

      // Mettre à jour les données à envoyer au serveur
      const updatedData = {
        ...editedData,
        tache_developpeur: tacheDeveloppeurString,
      };

      // Appeler onSave avec les données mises à jour
      onSave(updatedData);
      onHide();
    };

    return (
      <Modal
        show={show}
        onHide={handleCloseAssignModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Assignation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <form>
            <div>
              <div className="form-group">
                <input
                  type="text"
                  name="id"
                  className={`form-control`}
                  value={editedData.id}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>
                  Choisir des développeurs:
                </label>
                {developpeur.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="tache_developpeur"
                      value={item.id}
                      onChange={handleChange}
                      style={{ marginRight: "8px" }}
                    />
                    <span>{item.developpeur_nom}</span>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseAssignModal}>
            Fermer
          </Button>
          <Button
            variant="primary"
            onClick={handleAssignClick}
            style={{ backgroundColor: "#014F77", color: "white" }}
          >
            Assigner
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModifyTacheModal = ({ show, onHide, formData }) => {
    const [editedData, setEditedData] = useState(formData);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedData({
        ...editedData,
        [name]: value,
      });
    };
    return (
      <Modal show={show} onHide={onHide} style={{ top: "10%", left: "0%" }}>
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Modifier les détails de la tâche
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="modal_projet">
              <input
                type="hidden"
                name="id"
                className={`form-control`}
                value={editedData.id}
                onChange={handleChange}
              />
            </div>
            <div className="modal_projet">
              <label>Nom du projet:</label>
              <select
                name="tache_projet"
                value={editedData.tache_projet}
                onChange={handleChange}
                className={`form-control ${errors.tache_projet ? "error" : ""}`}
              >
                {projets.map((item) => (
                  <option key={item.id} value={item.projet_nom}>
                    {item.projet_nom}
                  </option>
                ))}
              </select>
              {errors.tache_projet && (
                <span className="error_message">{errors.tache_projet}</span>
              )}
            </div>
            <div className="modal_projet">
              <label>Nom de la tâche:</label>
              <input
                type="text"
                name="tache_nom"
                value={editedData.tache_nom}
                onChange={handleChange}
                className={`form-control`}
              />
            </div>
            <div className="form-group">
              <label>Déscription:</label>
              <textarea
                type="text"
                name="tache_description"
                value={editedData.tache_description}
                onChange={handleChange}
                className={`form-control`}
              />
            </div>
            <div className="form-group">
              <label>Priorité tache:</label>
              <select
                name="tache_priorite"
                value={editedData.tache_priorite}
                onChange={handleChange}
                className={`form-control`}
              >
                <option value="Elevée">Élevée</option>
                <option value="Moyenne">Moyenne</option>
                <option value="Faible">Faible</option>
              </select>
            </div>
            <div className="form-group">
              <label>Estimation de la réalisation:</label>
              <input
                type="number"
                name="tache_estimation"
                value={editedData.tache_estimation}
                onChange={handleChange}
                className={`form-control`}
              />
            </div>
            <div className="from-group">
              <label>Date de début:</label>
              <input
                type="date"
                name="tache_date_debut"
                value={editedData.tache_date_debut}
                onChange={handleChange}
                className={`form-control`}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={() => {
              modifTache(editedData.id, editedData);
              onHide();
            }}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="taches_conteneur">
      <div className="nav_tahes">
        <NavbarMain />
      </div>
      <div className="titre_taches">
        <h5 className="taches_titre">GESTION DES TACHES</h5>
      </div>
      <div className="container" style={{ display: "flex", marginLeft: "0%" }}>
        <div className="ajout_taches">
          <button
            className="btn btn-primary"
            onClick={handleShowModal}
            style={{
              textDecoration: "none",
              backgroundColor: "#014F77",
              marginRight: "7px",
            }}
          >
            <span className="bi bi-plus-lg"></span>Ajouter
          </button>
        </div>
        <div className="recherche_projet">
          <div className="input-group mb-3 custom-search">
            <input
              type="text"
              placeholder="Rechercher..."
              className="form-control"
              style={{
                borderRadius: "5px 0 0 5px",
                borderTop: "2px solid #ccc",
                borderBottom: "2px solid #ccc",
              }}
            />
          </div>{" "}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ top: "6%", left: "0%" }}
      >
        <Modal.Header
          closeButton
          style={{ background: "#014F77", textAlign: "center" }}
        >
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Ajouter ici les détails de tâche
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAjouterClick}>
            <div className="modal_projet">
              <label>Nom du projet:</label>
              <select
                name="tache_projet"
                value={formData.tache_projet}
                onChange={handleInputChange}
                className={`form-control ${errors.tache_projet ? "error" : ""}`}
              >
                <option value="">Veuillez sélectionner un projet</option>
                {projets.map((item) => (
                  <option key={item.id} value={item.projet_nom}>
                    {item.projet_nom}
                  </option>
                ))}
              </select>
              {errors.tache_projet && (
                <span className="error_message">{errors.tache_projet}</span>
              )}
            </div>

            <div className="modal_projet">
              <label>Nom de la tâche:</label>
              <input
                type="text"
                name="tache_nom"
                value={formData.tache_nom}
                onChange={handleInputChange}
                className={`form-control ${errors.tache_nom ? "error" : ""}`}
              />
              {errors.tache_nom && (
                <span className="error_message">{errors.tache_nom}</span>
              )}
            </div>
            <div className="form-group">
              <label>Déscription:</label>
              <textarea
                type="text"
                name="tache_description"
                value={formData.tache_description}
                onChange={handleInputChange}
                className={`form-control ${
                  errors.tache_description ? "error" : ""
                }`}
              />
              {errors.tache_description && (
                <span className="error_message">
                  {errors.tache_description}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Priorité de la tache:</label>
              <select
                name="tache_priorite"
                value={formData.tache_priorite}
                onChange={handleInputChange}
                className={`form-control ${
                  errors.tache_priorite ? "error" : ""
                }`}
              >
                <option value="">Sélectionnez une priorité</option>
                <option value="Elevée">Élevée</option>
                <option value="Moyenne">Moyenne</option>
                <option value="Faible">Faible</option>
              </select>
              {errors.tache_priorite && (
                <span className="error_message">{errors.tache_priorite}</span>
              )}
            </div>
            <div className="from-group">
              <label>Date de début:</label>
              <input
                type="date"
                name="tache_date_debut"
                value={formData.tache_date_debut}
                onChange={handleInputChange}
                className={`form-control ${
                  errors.tache_date_debut ? "error" : ""
                }`}
              />
              {errors.tache_date_debut && (
                <span className="error_message">{errors.tache_date_debut}</span>
              )}
            </div>

            <div className="from-group">
              <label>Estimation:</label>
              <input
                type="number"
                name="tache_estimation"
                value={formData.tache_estimation}
                onChange={handleInputChange}
                className={`form-control ${
                  errors.tache_estimation ? "error" : ""
                }`}
              />
              {errors.tache_estimation && (
                <span className="error_message">{errors.tache_estimation}</span>
              )}

              <input
                type="hidden"
                name="tache_date_debut"
                value={formData.tache_developpeur}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            style={{ backgroundColor: "#014F77", color: "white" }}
            onClick={handleAjouterClick}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
      <DataTable
        columns={columns}
        data={taches}
        customStyles={customStyles}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 100]}
        conditionalCellStyles={[
          {
            when: (row) => true, // Appliquer toujours le style
            style: (row) => priorityCellStyle(row),
          },
        ]}
      />

      <ModifyTacheModal
        show={showModifyModal}
        onHide={() => setShowModifyModal(false)}
        formData={tacheToEdit}
        onUpdate={handleUpdateTache}
        onSave={saveModifiedTache}
      />

      <AssignTacheModal
        show={showAssignModal}
        onHide={() => setShowAssignModal(false)}
        formData={tacheToEdit}
        onUpdate={handleUpdateTache}
        onSave={(updatedData) => {
          assignTache(updatedData.id, updatedData);
        }}
      />

      <TacheDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        tache={selectedTache}
      />
      <div className="footer_taches">
        <Copyright />
      </div>
    </div>
  );
};

export default Taches;
