import React from 'react'
import '../Css/sidedev.css'

const SideDev = ({ active }) => {
  return (
    <div className={`sideDev ${active ? 'active' : ''}`}>
       <ul>
        <li className='proff'><span class="bi bi-person"></span>Mon Profil</li>
        <li className='rapport'><span class="bi bi-file-check"></span>Rapport</li>
        <li className='deco'><span class="bi bi-box-arrow-in-right"></span>Se deconnecter</li>
      </ul>
    </div>
  );
};

export default SideDev
